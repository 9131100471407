import React from "react";
import {UnControlled as CodeMirror} from 'react-codemirror2';
import { useHistory } from "react-router-dom";
//Components
import {Form, Button, Stack, Container, Row, Col, Alert} from "react-bootstrap";
import TextArea from "../../parts/TextArea";


//Images
import bgImagePrimary from '../../images/bgImagePrimary.png';

//Styles
import s from "./style.module.scss"

export interface ButtonType {
    completeTask: any;
    isAuth: boolean;
    isCorrectly: boolean;
    isVIP: boolean;
}

const Buttons = (props: ButtonType) => {

    let history = useHistory();

    const gotoSignup = () => {
        history.push("/user/register");
    }
    const completeTask = () => {
        props.completeTask()
    }

    if (props.isAuth && props.isCorrectly){
        return (
            <>
              <div className="vr"/>
              <Button className="orangeBtn btn-success" onClick={completeTask}>
                Complete task
              </Button>
            </>
            )
    }else if (!props.isAuth && props.isCorrectly){
        return (
            <>
              <div className="vr"/>
              <Button className="orangeBtn btn-success" onClick={gotoSignup}>
                Signup to save progress
              </Button>
            </>
            )
    }else{
        return (
            <></>
            )
    }
}

const CodeSection = (props: any) => {
    const handleCodeInput = (e: any) => {
        props.handleCodeInput(e)
    }
    // console.log(props.currentTask)
    return (
        <div className={s.codeSection}>
            <Container fluid className="p-0">
                <Row>
                    <Col md={8}>
                        <div className={s.taskSection}>
                            <h3 className={s.taskSectionHeader}>In todays coding challenge we want to
                                you {props.currentTask.description}</h3>
                                <div className={s.solutionHints}>
                                    {props.currentTask.solutionHint && <>
                                        <strong>Hint/s:</strong>
                                        <pre>{props.currentTask.solutionHint}</pre>
                                        </>}
                                </div>
                            {/*<CodeMirror
                                value={props.currentTask.code}
                                options={{
                                    mode: 'javascript',
                                    theme: 'material',
                                    lineNumbers: true
                                }}
                                onChange={(editor, data, value) => {
                                }}
                            />*/}
                        </div>
                        <Form>
                            <TextArea
                                btn={
                                    <Buttons
                                        isAuth={props.isAuthObj.isAuth}
                                        isCorrectly={props.isCorrectly}
                                        completeTask={() => props.completeTask()}
                                        isVIP={props.isAuthObj.isVIP}
                                    />
                                }
                                runCode={props.runCode}
                                handleCodeInput={handleCodeInput}
                                code={props.code}
                            />
                            <Form.Group className="mb-3" controlId="inputTextarea">
                                <Form.Label className={s.notes}>Output: </Form.Label>
                                <Stack direction="horizontal" gap={5}>
                                    <div className={s.middelware}>{props.code.output}</div>
                                </Stack>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col>
                        <div className="bgImage">
                            <img src={bgImagePrimary} alt="bgImage"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CodeSection
