import React from "react";
import { Route, Redirect } from "react-router-dom";
import {connect} from "react-redux"
// import type {Store} from "../redux/store"
// import { AuthContext } from "./Auth";

const mapStateToProps = (state) => {
    return {
        isUserAuth: state.users.isUserAuth,
        isAuth: state.users.isAuth
    }
}

const IsUserAuth = ({ component: RouteComponent, ...rest }, props) => {
console.log(rest)
  return (
    <Route
      {...rest}
      render={routeProps =>
        !!rest.isAuth ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={"/user/login"} />
        )
      }
    />
  );
};


export default connect(mapStateToProps, {})(IsUserAuth)