import React from "react";
import { Button, Form, Stack } from "react-bootstrap";
import s from "./style.module.scss";
import type { codeSection } from "../redux/ReducerTypes";
import {Controlled as CodeMirror} from 'react-codemirror2';

interface TextAreaType {
    runCode: any;
    handleCodeInput: any;
    btn?: any | undefined;
    code: codeSection;
}

const TextArea = (props: TextAreaType) => {

    const runCode = (e: any) => {
        props.runCode(e)
    }

    const handleCodeInput = (e: any) => {
        props.handleCodeInput(e)
    }

    return (
        <>
            <Form.Group className="mb-3" controlId="codeTextarea">
                <Form.Label className={s.notes}>Write code here:</Form.Label>
                <CodeMirror
                    value={props.code.code}
                    options={{
                        mode: 'javascript',
                        theme: 'material',
                        lineNumbers: true
                      }}
                    onBeforeChange={(editor, data, value) => {
                        // console.log(editor, data, value);
                        handleCodeInput(value)
                    }}
                    onChange={(editor, data, value) => {}}
                />
            </Form.Group>
            <Stack className={s.buttonCase} direction="horizontal" gap={3}>
                <Button className="orangeBtn" onClick={(e) => runCode(e)}>
                    Run
                </Button>
                {props.btn}
            </Stack>
        </>
    )
}

export default TextArea
