import React from "react";
import { Link } from 'react-router-dom';
import cls from './style.module.scss';
import bgNotFound from '../../images/notFound.jpg';

class NotFound extends React.Component{

	render(){
		return (
			<>
				<div className="error404" id={cls.notFound404}>
					<div className={cls.bgNotFound} ><img src={bgNotFound} alt="notfound" /></div>
					<div className={cls.notFOundContent}>
						<h1 className="heading">404 NOT FOUND</h1>
						<p className="text">Looks like you have lost your way</p>
						<Link to="/" className="back_home">
							How about going home?
						</Link>
					</div>
				</div>
			</>
			)
	}
}

export default NotFound
