import React from "react";

import {UnControlled as CodeMirror} from 'react-codemirror2';
import Flatpickr from "react-flatpickr";
import { connect } from "react-redux";
import {Redirect, useHistory} from "react-router-dom";

import type { Store } from "../redux/store";

import { /*setFirstOutput, updateOutput, setTaskName, setDescription,
	setVIPDescription, setCode, setType,updateOutputsArr, setSample, setTaskData, setScheduledDate,*/ addUpdateChallenge }
	from "../redux/reducers/admin-reducer";

import { getURLParam } from "../hooks/Utility";

//Components
import {Form, InputGroup, FormControl, Stack, Button, DropdownButton, Dropdown, Container} from "react-bootstrap";
//Style
import s from "./style.module.scss"
import 'flatpickr/dist/flatpickr.css'


const mapStateToProps = (state: Store) => {
	return {
		isAuth: state.users.isUserAuth,
		// admin: state.admin.admin,
		// outputs: state.admin.outputs,
		// firstOutput: state.admin.firstOutput,
		tasks: state.feed.tasks
	};
}

const CreateEditTaskContext = (props: any) => {

	const taskIdParam = getURLParam('task')
	const [taskId, setTaskId] = React.useState(taskIdParam);
	const [hasOutputArr, hasOutputArrHandle] = React.useState(false);
	const [newOutputValue, setNewOutputValue] = React.useState("");

	const [currentChallenge, updateLocalChallenge] = React.useState({
		id: "",
		name: "",
		ref: "",
		type: "Daily",
		scheduledDate: new Date(),
		solutionHint: "",
		code: "",
		description: "",
		VIPdesc: "",
		isSample: false,
		members: [] as Array<string>,
		outputs: [] as Array<string>,
	})
	let history = useHistory()

	React.useEffect(()=>{

		if(props.tasks.length == 0){
			return
		}
		var currentTask: any;
		currentTask = props.tasks.find( (item : any) => item.id == taskId)
		
		if(currentTask != undefined && currentTask.ref){
			if( currentTask.scheduledDate && typeof currentTask.scheduledDate.toDate == 'function' ){
				currentTask.scheduledDate = currentTask.scheduledDate.toDate()
			}
			// props.setTaskData(currentTask)

			/*if( Array.isArray(currentTask.outputs) ){
				hasOutputArrHandle(true)
			}*/
			updateLocalChallenge({...currentTask})
		}else{
			history.push('/admin/task')
			// updateLocalChallenge(currentTask)
		}
		// console.log(currentTask)
	}, [props.tasks, taskId])




	const handleNewTaskName = (name: string) => {
		// props.setTaskName(name)
		updateLocalChallenge({...currentChallenge, name})
	};

	const chooseTypeHandler = (type: any) => {

		if(type == 'Daily' && !currentChallenge.scheduledDate){
            currentChallenge.scheduledDate = new Date()
        }
		updateLocalChallenge({...currentChallenge, type, scheduledDate: currentChallenge.scheduledDate})
		// props.setType(e)
	};

	const handleCodeField = (code: string) => {
		updateLocalChallenge({...currentChallenge, code})
		// props.setCode(code);
	}

	const handlesolutionHint = (solutionHint: string) => {
		updateLocalChallenge({...currentChallenge, solutionHint})
		// props.setCode(code);
	}

	const handleIsSampleField = ({target}: any) => {
		updateLocalChallenge({...currentChallenge, isSample: target.checked})
		// props.setSample(e.target.checked);
	}

	const handleDescriptionField = (description: string) => {
		updateLocalChallenge({...currentChallenge, description})
		// props.setDescription(description);
	}

	const handleVIPDescriptionField = (VIPdesc: string) => {
		updateLocalChallenge({...currentChallenge, VIPdesc})
		// props.setVIPDescription(description);
	}

	const handleOutputs = (output: string, index: number) => {

		let outputs:string[] = currentChallenge.outputs
		outputs[index] = output
		updateLocalChallenge({...currentChallenge, outputs})
		// props.setFirstOutput(output)
	}

	const addOutput = () => {
		let outputs = currentChallenge.outputs
		outputs.push(newOutputValue)
		updateLocalChallenge({...currentChallenge, outputs})
		setNewOutputValue("")
		// props.updateOutputsArr(output, prevOutput)
	}

	const removeOutput = (i:number) => {
		let outputs = currentChallenge.outputs
		delete outputs[i]
		updateLocalChallenge({...currentChallenge, outputs})
		// props.updateOutputsArr(output, prevOutput)
	}
	
	const handleScheduledDateField = (scheduledDate:Date) => {
		updateLocalChallenge({...currentChallenge, scheduledDate})
		// props.updateOutputsArr(output, prevOutput)
	}

	// const updateOutputArr = () => {
	// 	props.updateOutput();
	// 	hasOutputArrHandle(true)
	// }





	const handleSubmit = (event: any) => {
		event.preventDefault();
		
		/*let size = 0;
		for(let i in currentChallenge) {
			if(currentChallenge[i] !== "") {
				size++
			}
		}

		if(Object.keys(currentChallenge).length === size && (props.firstOutput || props.outputs.length >= 1)) {
			props.addUpdateChallenge(currentChallenge, props.firstOutput, props.outputs);
		}else{
			alert("Please fill in all inputs")
		}*/
		// console.log(currentChallenge)
		props.addUpdateChallenge(currentChallenge);
	}

	if( !props.isAuth.isAdmin || !props.isAuth.userID ){
		/*return (
			<Redirect to="/section/challenges" />
			)*/
	}
	return (
		<>
		{!props.isAuth.isAuth?
			<Redirect to="/user/login" />
		:
			<Container>
				<Form onSubmit={(e) => handleSubmit(e)} className={s.AdminPanelForm}>
					<Form.Label htmlFor="task_title">Challenge title and Challenge type</Form.Label>
					<Stack direction="horizontal" gap={5}>
						<InputGroup className="mb-3" id="title">
							<FormControl
								placeholder="Task name"
								aria-label="Task name"
								id="task_title"
								aria-describedby="basic-addon1"
								onChange={(e) => handleNewTaskName(e.target.value)}
								value={currentChallenge.name}
							/>
							<DropdownButton
								variant="outline-secondary"
								title={currentChallenge.type}
								id="input-group-dropdown-1"
								onSelect={(e) => chooseTypeHandler(e)}
							>
								<Dropdown.Item eventKey="Classic">Classic</Dropdown.Item>
								<Dropdown.Item eventKey="Daily">Daily</Dropdown.Item>
							</DropdownButton>
						</InputGroup>
					</Stack>

					<div className="row mb-3">
						<div className="col-sm-6">
							<label className="w-100 d-flex" style={{height: '100%', alignItems: 'center', cursor: 'pointer'}}>
								<Form.Group className="" controlId="formBasicCheckbox">
									<Form.Check
										type="checkbox"
										label="This is a sample task"
										value="isSample"
										checked={currentChallenge.isSample}
										onChange={(e) => handleIsSampleField(e)}
									/>
								</Form.Group>
							</label>
						</div>
						{currentChallenge.type == 'Daily'?
							<div className="col-sm-6">
								<label style={{paddingLeft: '12px', width: '100%'}}>
								<span>Schedule date</span>
									<Flatpickr
										data-enable-time
										options={{
											altInput: true,
											altFormat: "F j, Y",
											dateFormat: "Y-m-d",
											enableTime: false,
											allowInput: true,
										}}
										value={ new Date(currentChallenge.scheduledDate) }
										name="scheduled_date"
										onChange={([date]) => {
											handleScheduledDateField(date)
										}}
									/>
								</label>
							</div>
						:''}
					</div>
					
					<Stack direction="horizontal" gap={5}>
						<InputGroup>
							<div className="w-100">
								<Form.Label htmlFor="task_code">Hints for the challenge</Form.Label>
								<FormControl
									className={`${s.admin_textarea}`}
									as="textarea"
									aria-label="With textarea"
									placeholder="Hints: e.g make use of useEffects hook"
									id="task_code"
									onChange={(e) => handlesolutionHint(e.target.value)}
									value={currentChallenge.solutionHint}
								/>
							</div>
						</InputGroup>
						<InputGroup>
							<div className="w-100">
								<Form.Label htmlFor="suggested_solution">Suggested code solution</Form.Label>
								<CodeMirror
									className={s.codeSection}
									value={currentChallenge.code}
									options={{
										mode: 'javascript',
										theme: 'material',
										lineNumbers: true
									}}
									onChange={(editor, data, value) => {
										// console.log({editor, data, value})
										handleCodeField(value)
									}}
								/>
							</div>
						</InputGroup>
					</Stack>

					<Stack direction="horizontal" gap={5}>
						<InputGroup>
							<div className="w-100">
								<Form.Label htmlFor="task_description">Challenge description</Form.Label>
								<FormControl
									className={`${s.admin_textarea}`}
									as="textarea"
									id="task_description"
									aria-label="With textarea"
									placeholder="Description"
									onChange={(e) => handleDescriptionField(e.target.value)}
									value={currentChallenge.description}
								/>
							</div>
						</InputGroup>
						<InputGroup>
							<div className="w-100 my-4">
								<Form.Label htmlFor="vip_description">V.I.P description</Form.Label>
								<FormControl
									className={`${s.vipDesc} ${s.admin_textarea}`}
									as="textarea"
									id="vip_description"
									aria-label="With textarea"
									placeholder="V.I.P. Description"
									onChange={(e) => handleVIPDescriptionField(e.target.value)}
									value={currentChallenge.VIPdesc}
								/>
							</div>
						</InputGroup>
					</Stack>
					<Form.Label htmlFor="basic-url">Output/s</Form.Label>


					<InputGroup>
					{console.log(currentChallenge)}
						{currentChallenge.outputs.map((output: string, i: number) => {
							return <React.Fragment key={i}>
								<FormControl
									aria-describedby=""
									onChange={(e) => handleOutputs(e.target.value, i)}
									value={output}
									className={s.rule51}
								/>
								<Button onClick={e => removeOutput(i)} className={`${s.removeOutputBtn} btn btn-danger`} variant="outline-secondary" >x</Button>
							</React.Fragment>
						})}
						<FormControl
							aria-describedby="basic-addon3"
							onChange={(e) => setNewOutputValue(e.target.value)}
							value={newOutputValue}
							className={s.rule51}
						/>
						<Button onClick={addOutput} className={s.rule51Btn} variant="outline-secondary" >+</Button>
					</InputGroup>


					<InputGroup className="mb-3">

					</InputGroup>
					<Button type="submit">{ currentChallenge.ref ? 'Update Challenge' :  'Add new task'}</Button>
				</Form>
			</Container>
		}
		</>

	)
}

export default connect(
	mapStateToProps,
	{ 
		/*setFirstOutput,
		updateOutput,
		setTaskName,
		setDescription,
		setSample,
		setVIPDescription,
		setCode,
		setType,
		updateOutputsArr,
		setTaskData,
		setScheduledDate*/
		addUpdateChallenge,
	}
)(CreateEditTaskContext);
