import React from "react";

//Components
import {Container, Card, ListGroup, Stack} from "react-bootstrap";

//Styles
import s from "./style.module.scss"

const Blog = (props: any) => {
    const text = props.article;

    const [showFul, showFulHandler] = React.useState(false)

    return (
        <Container>
            <ListGroup className="mb-4" defaultActiveKey={`#$props.id`} variant="flush">
                <div className={s.Card}>
                    <div className={s.CardBody}>
                        <Stack className="mt-2">
                            <Card.Title>{props.title}</Card.Title>
                        </Stack>
                        <Card.Text>
                            {showFul ? text : `${text.slice(0, 250)}...`}
                        </Card.Text>
                    </div>
                    <div className={s.LinkButton}>
                        <Card.Link
                            className={s.link}
                            onClick={() => showFulHandler(!showFul)}
                        >
                            Read full article
                        </Card.Link>
                    </div>
                </div>
            </ListGroup>
        </Container>
    )
}

export default Blog
