import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";

import { getUserTasks, clearUserProfile } from "../../redux/reducers/profile-reducer"
import { checkUserAuth } from "../../redux/reducers/user-reducer"
import type { Store } from "../../redux/store";
import type { userTasks } from "../../redux/ReducerTypes";

//Styles
import s from "./style.module.scss"

//Components
// import { UnControlled as CodeMirror } from 'react-codemirror2';
import Profile from "./Profile";
import { ListGroup } from "react-bootstrap";

//Images
import bgImageSecondary from "../../images/bgImageSecondary.png";
import goal from "../../images/goal.webp";

const mapStateToProps = (state: Store) => {
    return {
        isUserAuth: state.users.isUserAuth,
        userTasks: state.profile.userTasks,
        tasks: state.feed.tasks,
    }
}

const ProfileContext = (props: any) => {
    React.useEffect(() => {
        props.checkUserAuth()
        props.getUserTasks(props.isUserAuth.email)

        return props.clearUserProfile()
    }, [props.isUserAuth.email])
    

    /*const [showHelp, showHelpHandler] = React.useState(false);
    const [help, helpHandler] = React.useState("");
    const [vip, setVIP] = React.useState("");
    const [title, titleHandler] = React.useState("");*/
    
    /*React.useEffect(() => {
        if(showHelp == true){
            document.querySelector('#codeHelpSection')?.scrollIntoView()
        }
    },[showHelp])*/

    return (
        <>
            { !props.isUserAuth.isAuth ?
                <Redirect to="/user/login" />
            :
                <div className={s.profileSection} style={{backgroundImage: `url(${goal})`}}>
                    <ListGroup className={`${s.main}`} defaultActiveKey={`#$props.id`} style={{opacity: '0.85'}}>
                        {(!props.isUserAuth.isVIP && props.isUserAuth.isMetaDataLoaded ) && <NavLink className={s.plan} to={`/section/payment`}>Upgrade to V.I.P.</NavLink>}

                            {props.userTasks.map((task: userTasks, index: number) => {
                                return (
                                    <Profile
                                        date={task.date}
                                        isCompleted={task.isCompleted}
                                        email={task.email}
                                        name={task.taskName}
                                        key={index}
                                        id={task.id}
                                        tasks={props.tasks}
                                        currentTask={task}
                                        isUserAuth={props.isUserAuth}
                                    />
                                )
                            })}
                    </ListGroup>
                    {/*<div className="bgImage">
                        <img src={bgImageSecondary} alt="bgImage"/>
                    </div>*/}
                </div>
            }
        </>
    )
}

export default connect(mapStateToProps, { getUserTasks, clearUserProfile, checkUserAuth })(ProfileContext)
