import React from "react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {finishRegistration} from "../../redux/reducers/codeValidate-reducer"

const mapStateToProps = (state: any) => {
    return {}
}

const FinishSignUp: React.FC <any> = (props) => {
    React.useEffect(() =>{
        props.finishRegistration()
    }, [])

    return (
        <>
            <h3>SignUp Completed...</h3>
            {window.close()}
        </>
    )
}

export default connect(mapStateToProps, {finishRegistration})(FinishSignUp)