import React from "react";
import {connect} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";

import {NavLink, Redirect} from "react-router-dom";

import {deleteChallenge} from "../redux/reducers/admin-reducer";
import {challengesRequest} from "../redux/reducers/feed-reducer";
import type {Store} from "../redux/store"

import {AiFillCaretDown, AiFillCaretUp} from "react-icons/ai"

import stl from "./style.module.scss"

const mapStateToProps = (state: Store) => {
    return {
        tasks: state.feed.tasks,
        user: state.users,
    }
}

const AdminPanelContext = (props: any) => {

    const [state, setSate] = React.useState({
        sortBy: "scheduledDate",
        sortOrder : "desc"
    })

    if( !props.user.isUserAuth.isAdmin || !props.user.isUserAuth.userID ){
        return (
            <Redirect to="/section/challenges" />
        )
    }

    const sortChallenges = (sortOrder:string, sortBy:string) => {
        setSate({sortBy, sortOrder })
        props.challengesRequest(state.sortBy, state.sortOrder)
    }
    const sortByDate = () => {
        const sortOrder = state.sortOrder == "desc" ? "asc" : "desc"
        sortChallenges( sortOrder, "scheduledDate" )
    }
    const sortByName = () => {
        const sortOrder = state.sortOrder == "desc" ? "asc" : "desc"
        sortChallenges( sortOrder, "name" )
    }
    const sortBySample = () => {
        const sortOrder = state.sortOrder == "desc" ? "asc" : "desc"
        sortChallenges( sortOrder, "isSample" )
        
    }
    const sortByParticipents = () => {
        const sortOrder = state.sortOrder == "desc" ? "asc" : "desc"
        sortChallenges( sortOrder, "members" )
    }

    return (
        <div className="px-3 mx-auto pt-4" style={{maxWidth: '1200px'}}>
            <div className="row px-3 pb-4" >
                <div className="col-sm-6">
                    <h1>All Challenges</h1>
                </div>
                <div className="col-sm-6 d-flex"  style={{justifyContent: 'end', alignItems: 'center', gap: '20px'}}>
                    <NavLink to="/admin/vip_links" className="btn btn-success">Manage VIP Links </NavLink>
                    <NavLink to="/admin/enrol_user" className="btn btn-success">Enroll User</NavLink>
                    <NavLink to="/admin/task" className="btn btn-success ml-3">Create New Challange</NavLink>
                </div>
            </div>
            <Container fluid className="w-100 px-3">

                <table className="table table-hover table-striped" >
                    <thead style={{background: '#777', color: '#fff'}}>
                        <tr>
                            <th scope="col">
                                #
                            </th>
                            <th
                                scope="col"
                                onClick={sortByName}
                                className={stl.cursorPointer}
                            >
                                Name
                                {state.sortBy == 'name' ?
                                    <>
                                        {state.sortOrder == 'desc' ? 
                                            <AiFillCaretDown />:
                                            <AiFillCaretUp />
                                        }
                                    </>
                                :''
                                }
                            </th>
                            <th
                                scope="col"
                                onClick={sortByParticipents}
                                className={stl.cursorPointer}
                            >
                                Participants
                                {state.sortBy == 'members' ?
                                    <>
                                        {state.sortOrder == 'desc' ? 
                                            <AiFillCaretDown />:
                                            <AiFillCaretUp />
                                        }
                                    </>
                                :''
                                }
                            </th>
                            <th
                                scope="col"
                                onClick={sortBySample}
                                className={stl.cursorPointer}
                            >
                                Sample Task
                                {state.sortBy == 'isSample' ?
                                    <>
                                        {state.sortOrder == 'desc' ? 
                                            <AiFillCaretDown />:
                                            <AiFillCaretUp />
                                        }
                                    </>
                                :''
                                }
                            </th>
                            <th
                                scope="col"
                                onClick={sortByDate}
                                className={stl.cursorPointer}
                            >
                                Scheduled Date
                                {state.sortBy == 'scheduledDate' ?
                                    <>
                                        {state.sortOrder == 'desc' ? 
                                            <AiFillCaretDown />:
                                            <AiFillCaretUp />
                                        }
                                    </>
                                :''
                                }
                            </th>
                            <th
                                scope="col"
                                className="text-center"
                            >
                                Options
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                            {props.tasks.map((task: any, index: number) => {
                                return (
                                    <tr className="align-middle" key={index}>
                                        <th>
                                            <div className="py-0">
                                                {task.defaultTask ? 
                                                <span style={{color: '#2196f3'}}>{index + 1}</span>:
                                                index + 1
                                                }
                                            </div>
                                        </th>
                                        <th>
                                                {task.defaultTask ? 
                                                <span style={{color: '#2196f3'}}>{task.name}</span>:
                                                task.name}
                                        </th>
                                        <td>
                                            {Array.isArray(task.members) ? task.members.length : 0}
                                        </td>
                                        <td>
                                            {task.isSample ? 'Yes' : 'No'}
                                        </td>
                                        <td>
                                            {
                                                (task.scheduledDate && typeof task.scheduledDate.toDate == 'function') ? 
                                                    task.scheduledDate.toDate().toDateString()
                                                    :
                                                    'Undefined'
                                            }
                                        </td>
                                        <td className="row mx-0 text-center">
                                            <div className="col-sm-6 px-1">
                                                <NavLink to={`/admin/task?task=${task.id}`} className="btn btn-primary">View/Edit</NavLink>
                                            </div>
                                            <div className="col-sm-6 px-1">
                                                <button onClick={e => props.deleteChallenge(task)} className="btn btn-danger">Delete</button>
                                            </div>
                                        </td>

                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </Container>
        </div>
    )
}

export default connect(mapStateToProps, {deleteChallenge, challengesRequest})(AdminPanelContext)
