import React from "react";
import {NavLink} from "react-router-dom";

//Styles
import s from './style.module.scss'

//Components
import {ListGroup, Stack} from "react-bootstrap";

const Feed = (props: any) => {

    const disableLink = (e: any) => {
        if(!props.runable){
            alert("You need to login to view this task")
        }
    }

    let {task}: any = props

    return (
        <ListGroup defaultActiveKey={`#${task.id}`} className={s.Card}>
            <Stack className="mt-2">
                <p>{task.name}</p>
                {task.isSample ? <sub style={{fontWeight: 'normal', fontSize: '12px', lineHeight: '1.5', textAlign: 'right'}}>(Sample task)</sub>:''}
            </Stack>
            <div className={s.LinkButton}>
                <NavLink to={`/section/code?task=${task.id}`} onClick={ e => disableLink(e) }>Start this task</NavLink>
            </div>
        </ListGroup>
    )
}

export default Feed
