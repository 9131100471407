import db, { auth } from "../instance";
import { collection, getDocs,  query, where, addDoc, doc } from 'firebase/firestore/lite';
import Base64 from 'crypto-js/enc-base64';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import type { regUser, LoginUser, userInfo, userAuthType } from "../../../redux/ReducerTypes";
import { sendSignInLinkToEmail, createUserWithEmailAndPassword, signInWithEmailLink, onAuthStateChanged,  } from "firebase/auth";

// NOT WORKING COMONENT. SHOULD BE REMOVED

const data = {} as regUser;
const user = data.name;
export const userAuth = {
    email: "",
    uid: "",
    isAuth: false
} as userAuthType

export const createUserSnippet = async (data: any) => {
    const docRef = await addDoc(collection(db, "user_snippets"), {
        ...data
    });
}

export const completeSighIn = (email: string | any, password: string | any) => {
    signInWithEmailLink(auth, email, window.location.href) // email verified
    .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        // window.localStorage.removeItem('tokenForSignIn')
    })
    .catch((error) => {
        console.log(error.message)
    });
}



export async function getAllUsers() {
    let users = collection(db, "users")
    let usersCollection = await getDocs(users)
    usersCollection.docs.map((docs: any) => {
        console.log(docs.data());
    })
}

