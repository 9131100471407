import './App.css';
import Main from './components/Main.jsx'
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux'
import React from 'react';
import store from "./redux/store";
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/theme/neat.css';
import 'codemirror/mode/xml/xml.js';
import 'codemirror/mode/javascript/javascript.js';

class App extends React.Component {
  render() {
    return (
      <Router>
        <Provider store={store}>
          <Main/>
        </Provider>
      </Router>
    )
  }
}

export default App;
