import type { ThunkDispatch } from "redux-thunk";
import db from "../../api/firestore/instance";
// import type {codeType, outputType} from "../ReducerTypes"
import { collection, getDocs, orderBy, query, setDoc, doc, limit } from "firebase/firestore";

export const initialState: any = {
    tasks: [
        
    ] as Array<any>,
    news: [

    ] as Array<any>,
    loaded: false,
}

const feedReducer = (state: typeof initialState = initialState, action: any): typeof initialState => {
    let newState = { ...state }
    newState.tasks = [...newState.tasks]
    newState.news = [...newState.news]

    const _uploadTask = (tasks: any) => {
        newState.tasks = tasks
        newState.loaded = true
    }

    const _uploadFeed = (feeds: any) => {
        newState.news = feeds
    }

    const _clearTable = () => {
        newState.tasks = []
    }

    switch (action.type) {
        case "CHALLENGES":
            _uploadTask(action.tasks);
            return newState;
        case "CLEAR_TASKS":
            _clearTable();
            return newState;
        case "SET_NEWS":
            _uploadFeed(action.news);
            return newState
        default:
            return state
    }
}

const challengesRequestActionCreator = (tasks: any) => ({ type: "CHALLENGES", tasks })
export const challengesRequest = (sortBy:string = "scheduledDate", sortOrder:any = "desc") => async (dispatch: ThunkDispatch<any, void, any>) => {
    let obj: any = {};
    let tasks: any = []
    
    const querySnapshot = await getDocs(
        query(
            collection(db, "challenges"),
            orderBy(sortBy, sortOrder )
            )
        );
    querySnapshot.forEach((doc) => {
        obj = {...doc.data()}
        obj.ref = doc.id
        tasks.push(obj)
    });

    /*tasks.forEach((task:any, index:number) => {        
        // fields to remove later
        // code, outputs

        let updatedTask:any = {
            ...task
        }
        delete updatedTask.ref
        // delete updatedTask.suggestedSolution
        if(updatedTask.code){
            console.log(index)
        }
        // delete updatedTask.code
        // delete updatedTask.outputs

        if(task.outputs){
            // setDoc( doc(db, "challenges", task.ref), {...updatedTask})
            // console.log(index)
        }
    })*/

    dispatch(challengesRequestActionCreator(tasks));
}

const newsRequestActionCreator = (news: any) => ({type: "SET_NEWS", news})
export const newsRequest = () => async (dispatch: ThunkDispatch<any, void, any>) => {
    let posts:any = [];
    const querySnapshot = await getDocs(collection(db, "feed"));
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        posts.push(doc.data())
    });
    dispatch(newsRequestActionCreator(posts));
}

const clearValueActionCreator = () => ({type: "CLEAR_TASKS"})
export const clearTable = () => (dispatch: ThunkDispatch<any, void, any>) => {
    dispatch(clearValueActionCreator())
}

export default feedReducer