import React from "react";
import {connect} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";

import {NavLink, Redirect} from "react-router-dom";
import type {Store} from "../redux/store"
import {createUsers} from "../redux/reducers/admin-reducer"
import { toast } from 'react-toastify';
import { CSVtoArray, isValidEmail } from "../hooks/Utility";

const mapStateToProps = (state: Store) => {
    return {
        user: state.users,
    }
}

const EnrollUsersContext = (props: any) => {

    if( !props.user.isUserAuth.isAdmin || !props.user.isUserAuth.userID ){
        return (
            <Redirect to="/section/challenges" />
        )
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        let textarea = e.target['user_emails']

        let lines = textarea.value.split(`\n`)

        let users:any = []

        lines.forEach((line: string) => {

            let lineParsed = CSVtoArray(line)

            if(line != '' && Array.isArray(lineParsed) && isValidEmail(lineParsed[0])){
                users.push({
                    email: lineParsed[0],
                    name: lineParsed[1],
                    password: lineParsed[2],
                })
            }
        })

        if(users.length > 0 && users[0] != undefined){
            props.createUsers(users, props.user.userRefId)
        }else{
            toast.error('Please enter some valid user data')
        }

    }

    return (
        <div className="px-3 mx-auto p-2" style={{maxWidth: '1200px'}}>
            <div className="row pt-3">
                <form onSubmit={handleSubmit}>
                    <div className="w-100 d-flex justify-content-end">
                        <button className="btn btn-primary">Enroll Users</button>
                    </div>

                    <label className="w-100">
                        Paste the User/s data [CSV formatted, 1 user per line]
                        <textarea
                            required={true}
                            name="user_emails"
                            style={{width: '100%', maxWidth: '100%', height: '250px', resize: 'none'}}
                            placeholder={`'email','name','password(optional)'\n'email','name','password(optional)'\n...\n...\n...\n'email','name','password(optional)'`}
                            defaultValue=""
                        ></textarea>
                    </label>
                </form>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, {createUsers})(EnrollUsersContext)
