import type { ThunkDispatch } from "redux-thunk"
import { onAuthStateChanged, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword } from "firebase/auth"
import { getDoc, setDoc, doc } from "firebase/firestore"
import db, { auth } from "../../api/firestore/instance"
import {clearValues} from "./codeValidate-reducer"
import type {regUser, LoginUser, userInfo, userAuthType, firestoreDoc} from "../ReducerTypes"
import { toast } from "react-toastify"
import {toastOptions, getURLParam} from "../../hooks/Utility";

export const initialState: userInfo = {
    isUserAuth: {
        email: "",
        uid: "",
        isAuth: false,
        userID : "",
        checked : false,
        isMetaDataLoaded: false,
    } as userAuthType,
    error: "",
    userRefId: "",
}

const userReducer = (state: typeof initialState = initialState, action: any): typeof initialState => {

    let newState = { ...state }
    newState.isUserAuth = { ...newState.isUserAuth }

    const _logout = () => {
        newState.isUserAuth.email = ''
        newState.isUserAuth.uid = ''
        newState.isUserAuth.isAuth = false
        newState.isUserAuth.userID = ''
        newState.isUserAuth.isVIP = false
        newState.isUserAuth.isAdmin = false
        newState.isUserAuth.isMetaDataLoaded = false
    }

    const _setUserAuth = (authData: userAuthType) => {

        newState.isUserAuth.email = authData.email
        newState.isUserAuth.uid = authData.uid
        newState.isUserAuth.isAuth = authData.isAuth
        newState.isUserAuth.userID = authData.userID
        newState.isUserAuth.isVIP = authData.isVIP
        newState.isUserAuth.isAdmin = authData.isAdmin
        newState.isUserAuth.isMetaDataLoaded = authData.isMetaDataLoaded

        return newState.isUserAuth;
    }
    
    const _saveRef = (ref: string) => {
        return newState.userRefId = ref.substring(6);
    }

    const _setError = (error: string) => newState.error = error
    
    const _hidePreLoader = () => newState.isUserAuth.checked = true

    switch (action.type) {
        case "SET_USER_AUTH":
            _setUserAuth(action.user)
            return newState;
        case "LOGOUT":
            _logout()
            return newState;
        case "SAVE_REF":
            _saveRef(action.ref);
            return newState;
        case "SET_ERROR":
            _setError(action.error);
            return newState
        case "HIDE_PRELOADER":
            _hidePreLoader();
            return newState
        default:
            return state
    }
}

export const forgotPassword = async (email: string) => {

    let toastID = toast.loading("Sending reset password link to your email..")
    fetch( `https://us-central1-algorithm-challenge.cloudfunctions.net/forgot_password`, {
    // fetch("http://127.0.0.1:5000/algorithm-challenge/us-central1/forgotPassword", {

        method: "POST",
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({email})
    })
    .then(res => res.json())
    .then(res => {

        if(res.code === 200){
            toast.update(toastID, {...toastOptions, render: "Reset email sent. Please check your email.", type: "success"})
        }else{
            toast.update(toastID, {...toastOptions, render: res.message, type: "warning"})
        }
    })
    .catch(e => {
        toast.update(toastID, {...toastOptions, render: "There were some error sending password reset email. please try again later.", type: "error"})
        console.log(e)
    })

}

// Add a new document with a generated id.
// export async function addNewUserToDB(data: regUser, dispatch: ThunkDispatch<userInfo, void, any>) {
export const logUsers = (data: regUser) => (dispatch: any) => {
    const newData = {
        email: data.email,
        creator: '',
        completedTask: [],
        name: data.name,
    }
    let couponCode = getURLParam('_track_id')
    createUserWithEmailAndPassword(auth, data.email, data.password) // create credentials for new user
    .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        try {
            newData.creator = user.uid
            setDoc(doc(db, "users", user.uid), newData)
            .then(r => {
                if(couponCode){
                    // Send a single with a hope to make VIP because it have coupon code
                    navigator.sendBeacon(`https://us-central1-algorithm-challenge.cloudfunctions.net/make_vip`, JSON.stringify({coupon: couponCode, uid: user.uid}))
                    // navigator.sendBeacon(`http://127.0.0.1:5000/algorithm-challenge/us-central1/make_vip`, JSON.stringify({coupon: couponCode, uid: user.uid}))
                }
            })
        }
        catch(err: any){
            dispatch(handleErrorActionCreator( err.message ))
        }
    })
    .catch((e) => {
        console.log(e)
        dispatch(handleErrorActionCreator( e.message ))
    })
}



/** @FIREBASE API'S QUERIES */
const handleErrorActionCreator = (error: string) => ({ type: "SET_ERROR", error})

export const handleError = (error: string | undefined) => (dispatch: ThunkDispatch<userInfo, void, any>) => {
    if(error !== undefined && error !== '' ) {
        dispatch(handleErrorActionCreator(error));
    }else{
        dispatch(handleErrorActionCreator(''));
    }
}

const saveUserRefIdActionCreator = (ref: string) => ({type: "SAVE_REF", ref})
export const checkUserAuthActionCreator = (user: userAuthType) => ({ type: "SET_USER_AUTH", user });
export const checkUserAuth = () => (dispatch: ThunkDispatch<userInfo, void, any>) => {

    async function getUserMetaData(user: any) {
        try{
            getDoc(doc(db, 'users', user.uid))
            .then( (profile: firestoreDoc) =>{

                let profileData = profile.data()
                dispatch(saveUserRefIdActionCreator(profile.ref.path));
                dispatch(checkUserAuthActionCreator((
                    {
                        email: user.email,
                        uid: user.uid,
                        isAuth: true,
                        userID: profileData.id,
                        isVIP: profileData.isVIP,
                        isAdmin: profileData.isAdmin,
                        isMetaDataLoaded: true,
                    }
                )));
            })
            .catch(e => console.log(e))
        }catch(e){
            console.log(e)
        }
        dispatch({type: 'HIDE_PRELOADER'})

    }

    onAuthStateChanged(auth, (user: any) => {
        if (user) {
            dispatch(checkUserAuthActionCreator({
                    email: user.email,
                    uid: user.uid,
                    isAuth: true,
                    userID: '',
                    isVIP: false,
                    isMetaDataLoaded: false,
                }));
            getUserMetaData(user)
        }
        dispatch({type: 'HIDE_PRELOADER'})
    })
}

export const loginUser = (user: LoginUser) => {
    return (dispatch: ThunkDispatch<userInfo, void, any>) => {

        signInWithEmailAndPassword(auth, user.email, user.password)
        .then((userCredential) => {
            // Signed in 
            checkUserAuth()
            dispatch(clearValues(false))
        })
        .catch((error) => {
            dispatch(handleErrorActionCreator(error.message));
        });
    }
}

const logoutActionCreator = () => ({ type: "LOGOUT" })
export const logout = () => (dispatch: ThunkDispatch<userInfo, void, any>) => {
    signOut(auth).then(() => {
        // Sign-out successful.
        dispatch(logoutActionCreator())
    }).catch((error) => {
        dispatch(handleErrorActionCreator(error.message));
        // An error happened.
    });
}

export default userReducer