// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import {getAuth} from 'firebase/auth'

import { getAnalytics } from "firebase/analytics";
// import {initializeFirestore} 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDrakSGbD9-GD4-vhge5KMfiphPkWbddFE",
  authDomain: "algorithm-challenge.firebaseapp.com",
  projectId: "algorithm-challenge",
  storageBucket: "algorithm-challenge.appspot.com",
  messagingSenderId: "871264449003",
  appId: "1:871264449003:web:69d0b5713e86fa326ba9ae",
  measurementId: "G-Q0HL7HNHDM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const auth = getAuth();
// const db = initializeFirestore();

export const actionCodeSettings = (path: string) => {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  let url = `http://localhost:3000/user/finishSignUp?cartId=${path}`;
  // This must be true.
  let handleCodeInApp = true;
  return {
      url,
      handleCodeInApp
  }
};

export default db;