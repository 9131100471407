import React from "react";
import { connect } from "react-redux";
import {Redirect} from "react-router-dom";

import type { Store } from "../redux/store";
import { setTitle, setArticle, createNewPost } from "../redux/reducers/admin-reducer";

//Components
import {Button, FormControl, InputGroup, Form, Container} from "react-bootstrap";

//Styles
import s from "./style.module.scss";

const mapStateToProps = (state: Store) => {
    return {
        feed: state.admin.feed,
        isAuth: state.users.isUserAuth,
    }
}

const FeedAdminPanel = (props: any) => {

    const handleArticle = (article: string) => {
        props.setArticle(article);
    }

    const handleTitle = (title: string) => {
        props.setTitle(title);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        props.createNewPost(props.feed)
    }

    return (
        <>
        {!props.isAuth.isAuth?
            <Redirect to="/user/login" />
        :
            <Container>
                <Form onSubmit={handleSubmit} className={s.AdminPanelForm}>
                    <InputGroup className="mb-3">
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Title"
                            onChange={(e) => handleTitle(e.target.value)}
                            value={props.feed.title}
                        />
                    </InputGroup>

                    <InputGroup>
                        <FormControl className={`${s.admin_textarea}`}
                                     as="textarea"
                                     aria-label="With textarea"
                                     placeholder="Article"
                                     onChange={(e) => handleArticle(e.target.value)}
                                     value={props.feed.article}
                        />
                    </InputGroup>
                    <Button style={{marginTop: "15px"}} type="submit">Add new post</Button>
                </Form>
            </Container>
        }
        </>
    )
}

export default connect(mapStateToProps, { setTitle, setArticle, createNewPost })(FeedAdminPanel);
