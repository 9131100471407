import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import userReducer from "./reducers/user-reducer";
import codeReducer from "./reducers/code-reducer";
import valuesValidateReducer from "./reducers/codeValidate-reducer";
import feedReducer from "./reducers/feed-reducer";
import profileReducer from "./reducers/profile-reducer";
import paymentReducer from "./reducers/payment-reducer";
import adminReducer from "./reducers/admin-reducer";
import vipLinksReducer from "./reducers/vip-links-reducer";

import type {userInfo, codeType, userTasksType, paymentType, Admin, vipLinksType } from "./ReducerTypes";

export interface Store {
    users: userInfo;
    code: codeType;
    validate: userInfo;
    feed: any;
    profile: userTasksType;
    payment: paymentType;
    admin: Admin;
    vipLinks: vipLinksType;
}

const reducers = combineReducers({
    users: userReducer,
    code: codeReducer,
    validate: valuesValidateReducer,
    feed: feedReducer,
    profile: profileReducer,
    payment: paymentReducer,
    admin: adminReducer,
    vipLinks: vipLinksReducer,
});


declare global {
    interface Window {
        store: any;
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

let store : any;
if( window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ){
    store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__( applyMiddleware(thunk) ) );
}else{
    store = createStore(reducers, applyMiddleware(thunk) );
}

window.store = store || {};
export default store;