import React from "react";
import Log from "./Log";
import { connect } from "react-redux";
import {Redirect} from "react-router-dom";
import type { Store } from "../../redux/store"
import { savePassword, saveEmail } from "../../redux/reducers/codeValidate-reducer";
import { loginUser, handleError } from "../../redux/reducers/user-reducer";

//Styles
import cls from './style.module.scss'

const mapStateToProps = (state: Store) => {
    return {
        loginCase: state.validate.loginCase,
        isAuth: state.users.isUserAuth,
    }
}

const Login: React.FC<any> = (props) => {

    React.useEffect(() => {
        return () => {
            props.handleError("")
        }
    }, [])

    return (
        <>
        {props.isAuth.isAuth?
            <Redirect to="/section/challenges" />
        :
            <div className={`${cls.loginForm} px-3`}>
                <Log
                    loginCase={props.loginCase}
                    regUser={false}
                    saveEmail={props.saveEmail}
                    savePassword={props.savePassword}
                    loginUser={props.loginUser}
                />
           </div>
       }
       </>

    )
}

export default connect(mapStateToProps, { savePassword, saveEmail, loginUser, handleError })(Login)
