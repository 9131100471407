import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import type {Store} from "../../redux/store"
import {savePassword, validatePassword, saveEmail, saveUsername} from "../../redux/reducers/codeValidate-reducer";
import {logUsers, handleError } from "../../redux/reducers/user-reducer";


//Components
import Log from "./Log";

//Styles
import cls from "./style.module.scss";

const mapStateToProps = (state: Store) => {
    return {
        registerCase: state.validate.registerCase,
        isAuth: state.users.isUserAuth,
    }
}

const Register: React.FC<any> = (props) => {
    React.useEffect(() => {
        return () => {
            props.handleError("")
        }
    }, [])

    return (
        <>
        {props.isAuth.isAuth?
            <Redirect to="/section/challenges" />
        :
            <div className={`${cls.loginForm} px-3`}>
                <Log
                    registerCase={props.registerCase}
                    regUser={true}
                    saveEmail={props.saveEmail}
                    savePassword={props.savePassword}
                    validatePassword={props.validatePassword}
                    saveUsername={props.saveUsername}
                    logUsers={props.logUsers}
                    handleError={props.handleError}
                />
            </div>
        }
        </>
    )
}

export default connect(mapStateToProps,
    { savePassword, validatePassword, saveEmail, saveUsername, logUsers, handleError })
    (Register)
