import React from "react";
import { connect } from "react-redux";
import {Redirect, NavLink} from 'react-router-dom';

import { inputCode, inputValue, getOutput, clearOutput, getInfo, completeTask, startTask, leavePage
    ,uploadCode, getTask } from "../../redux/reducers/code-reducer";
import {searchTask, getURLParam} from "../../hooks/Utility"
import type { Store } from "../../redux/store"
import FadingDots from "react-cssfx-loading/lib/FadingDots";
import Login from '../login/LoginContext';
import stl from './style.module.scss'
//Components
import {Container} from "react-bootstrap";
import CodeSection from "./CodeSection";

const mapStateToProps = (state: Store) => {
    return {
        code: state.code.codeSection,
        isAuthObj: state.users.isUserAuth,
        refferal: state.users.userRefId,
        tasks: state.feed.tasks,
        isCorrectly: state.code.isCorrectly,
    }
}

const Landing: React.FC<any> = (props) => {

    const [currentTask, setCurrentTask] = React.useState({
        VIPdesc: '',
        code: '',
        description: '',
        id: '',
        name: '',
        ref: '',
        type: "Daily",
        outputs : [],
        members: [],
        isSample: false,
        solutionHint: "",
        loading: true,
        loadingInitialized: false,
    })

    const taskID = getURLParam('task')
    React.useEffect(()=>{

        if( !currentTask.loadingInitialized ){
            // set the call to run only once when the page is loaded
            setCurrentTask({...currentTask, loadingInitialized: true})
            setChallenge(taskID)
        }

    },[])

    React.useEffect(() => {
        props.getInfo(taskID);

        return finalizer();
    }, [])

    const setChallenge = (challengeID:string) => {
        getTask(challengeID)
        .then(res =>{
            if(res.docs.length > 0){
                setTaskFields(res.docs[0].data(), res.docs[0].id)
            }else{
                setCurrentTask({...currentTask, loading: false})
            }
        })
        .catch(e => {
            console.log(e)
        })
    }

    const setTaskFields = (doc:any, ref: string) => {
        let task = {
            VIPdesc:     doc.VIPdesc,
            code:        doc.code,
            solutionHint:doc.solutionHint,
            description: doc.description,
            id:          doc.id,
            name:        doc.name,
            type:        doc.type,
            outputs:     doc.outputs,
            isSample:    doc.isSample,
            // Members field can be removed later for security reasons. but for now, lets just keep it
            members:     doc.members,
            loading:     false,
            loadingInitialized: true,
            ref,
        }
        setCurrentTask(task)
    }

    const finalizer = () => { // when page close...
        props.clearOutput();
        props.leavePage()
    }

    const runCode = (e: any) => {
        e.preventDefault();
        if( props.isAuthObj.email ){
            props.startTask(props.isAuthObj, currentTask, setTaskFields, props.code)
        }
        // console.log(currentTask)
        props.getOutput(props.code, currentTask.outputs)
    }

    const completeTask = () => {
        props.completeTask(props.isAuthObj, currentTask)
    }

    const handleCodeInput = (event: any) => {
        const target = event.target
        props.inputCode(event)
    }

    return (
        <>
            { ( currentTask.loading ) ?

                <>
                    <div className="pagePreloader">
                        <FadingDots width="150px" height="150px" color="#62d3f1" />
                    </div>
                </>
            :
            <>
                { currentTask.id ?
                    <>
                
                    {/* { (!props.isAuthObj.isAuth && !currentTask.isSample) ?
                        <Login />
                    : */}
                        <Container className="container-fluid mw-100 p-0">
                            <CodeSection
                                currentTask={currentTask}
                                runCode={runCode}
                                handleCodeInput={handleCodeInput}
                                completeTask={completeTask}
                                code={props.code}
                                isAuthObj={props.isAuthObj}
                                isCorrectly={props.isCorrectly}
                            />
                        </Container>
                    {/* } */}
                    </>
                    :
                    <>
                        <div className={stl.noTaskFound}>
                            <p>
                                <h1>No challenge found for today.</h1>
                                {/*<NavLink to="/section/challenges">View All Challenges</NavLink>*/}
                            </p>
                        </div>
                    </>
                }
            </>
            }
        </>
    )
}

export default connect(mapStateToProps,
    { inputCode, inputValue, getOutput, clearOutput, getInfo, completeTask, startTask, leavePage
    ,uploadCode })(Landing)
