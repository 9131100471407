import React from "react";
import { useHistory } from "react-router-dom";

import {searchTask, getURLParam} from "../../hooks/Utility"
import { UnControlled as CodeMirror } from 'react-codemirror2';
//Styles
import s from "./style.module.scss";

//Components
import { Card, Button, ListGroup, Stack } from "react-bootstrap";

const Profile = (props: any) => {
    const history = useHistory();
    const currentTask = searchTask(props.tasks, props.id)

    const [Help, helpHandle] = React.useState({
        show: false,
        title: '',
        vip: '',
        code: '',
        userCode: '',
    })

    const alertClicked = () => {
        console.log(props.currentTask)
        helpHandle({
            show: !Help.show,
            title: currentTask.name || props.name,
            vip: currentTask.VIPdesc || currentTask.description,
            code: currentTask.code,
            userCode: props.currentTask.userCode || '',
        })
    }

    const finishTask = () => {
        history.push(`/section/code?task=${props.id}`)
    }

    let date = props.date;
    if(typeof date.toDate == 'function'){
        date = date.toDate().toDateString()
    }
    return (
        <>
            <ListGroup.Item className={s.listItem} action onClick={alertClicked}>
                <Card.Body className={s.CardBody}>
                    <Stack>
                        <Card.Title>
                            <Card.Text>{props.name}</Card.Text>
                        </Card.Title>
                    </Stack>
                    <Card.Text>
                        Date: {date}
                    </Card.Text>
                </Card.Body>
               <div className={s.LinkButton}>
                   {props.isCompleted ?
                       <Card.Text>
                           Completed
                       </Card.Text> :
                       <div>
                           <Button onClick={finishTask}>Finish task</Button>
                       </div>
                   }
               </div>
            </ListGroup.Item>

            {Help.show ? 
                <div className={s.ResultCard} id="codeHelpSection">
                    <h2 className={s.ResultCardHeader}>{Help.title}</h2>
                    <div className={s.descriptions}>
                        <h3 style={{fontWeight: 'bold'}}>Description</h3>
                        <p style={{fontSize: "20px"}} >{currentTask.description}</p>
                        {currentTask.solutionHint && <>
                            <h4>Hint/s:</h4>
                            <pre>{currentTask.solutionHint}</pre>
                        </>}
                    </div>
                    {props.isUserAuth.isVIP &&
                    <div>
                        {Help.vip != '' ?
                            <div className={s.descriptions}>
                                <h3 style={{fontWeight: 'bold'}}>VIP Description</h3>
                                <p style={{fontSize: "20px"}} >{Help.vip}</p>
                            </div>: ''
                            }
                    <h3 style={{fontWeight: 'bold'}}>Suggested Solution</h3>
                    <CodeMirror
                        value={Help.code}
                        options={{
                            mode: 'javascript',
                            theme: 'material',
                            lineNumbers: true
                        }}
                        onChange={(editor, data, value) => {
                        }}
                    />
                    </div>}
                    <h3 style={{fontWeight: 'bold'}}>Your Code</h3>
                        <CodeMirror
                            value={Help.userCode}
                            options={{
                                mode: 'javascript',
                                theme: 'material',
                                lineNumbers: true
                            }}
                            onChange={(editor, data, value) => {
                            }}
                        />
                </div>
                : null}
        </>
    )
}

export default Profile
