import React from "react";
import {connect} from "react-redux"
import {NavLink} from "react-router-dom";
import {isValidEmail} from "../../hooks/Utility";
import {Form, Button, Alert} from "react-bootstrap";
import {forgotPassword} from "../../redux/reducers/user-reducer";
import stl from './style.module.scss'


const mapStateToProps = (state: any) => {
    return {}
}
const ForgotPassword: React.FC<any> = (props) => {

    const validateEmail = ({target}:any)=>{

        if(!isValidEmail(target.value)){
            target.setCustomValidity('Please enter a valid email address.')
        }else{
            target.setCustomValidity('')
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        let email = e.target['email']
        forgotPassword(email.value)

    }

    return (
        <>
            <div className="px-4">
                <Form className={stl.LoginForm} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            required={true}
                            name="email"
                            type="email"
                            placeholder="name@example.com"
                            onChange={validateEmail}
                        />
                    </Form.Group>
                    <Button className="w-100 p-2 mt-3" variant="primary" type="submit" size="sm" active>
                        Reset Password
                    </Button>
                    <Form.Group className="mt-3 text-center" controlId="formBasicCheckbox">
                        <h2 className="mt-4 mb-2">OR</h2>
                        <NavLink className="w-100" to="/user/login">
                            Login to your account
                        </NavLink>
                    </Form.Group>
                </Form>
            </div>
        </>
    )

    /*return (
    <div className="text-center d-flex justify-content-center align-items-center" style={{height: 'calc(100vh - 100px)'}}>
        Recover Password page comming soon
    </div>
    )*/
}

export default connect(mapStateToProps, {})(ForgotPassword);