import React from "react";
import {NavLink} from "react-router-dom";

//Components
import {Form, Button, Alert} from "react-bootstrap";

//Styles
import s from './style.module.scss'

const FormController: React.FC<any> = (props) => {
    return (
        <>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                    required={true}
                    type="email"
                    placeholder="name@example.com"
                    onChange={e => props.validateEmail(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    required={true}
                    type="password"
                    placeholder="Password"
                    onChange={(e) => {
                        props.savePassword(e.target.value);
                    }}
                />
            </Form.Group>
        </>
    )
};

const Login: React.FC<any> = (props: any) => {
    const Log = props.regUser ? "Register" : "Login";

    const validateEmail = (email: string) => {
        props.regUser ? props.saveEmail(email, true) : props.saveEmail(email, false);
    };

    const savePassword = (password: string) => {
        props.regUser ? props.savePassword(password, true) : props.savePassword(password, false);
    };

    const validatePassword = (password: string) => props.validatePassword(password);

    const validateUsername = (username: string) => props.saveUsername(username);

    const submitUserInputs = (e: any) => {

        // debugger
        const value = {
            email: props.registerCase.email,
            name: props.registerCase.name,
            password: props.registerCase.password,
            isPassValid: props.registerCase.isPassValid,
            isVIP: false,
        } as any;
        e.preventDefault();
        props.handleError('')
        props.logUsers(value);
    }

    const authUser = (e: any) => {
        const value = {
            email: props.loginCase.email,
            password: props.loginCase.password,
            isVIP: false
        } as any;
        e.preventDefault();
        props.loginUser(value);
    }

    return (
        <>
            <Form className={s.LoginForm} onSubmit={e => props.regUser ? submitUserInputs(e) : authUser(e)}>
                <Alert.Heading className={`${s.LoginFormHeader} mb-4 text-center`}>{Log}</Alert.Heading>
                {props.regUser ?
                    <>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="username"
                                placeholder="Username"
                                required={true}
                                onChange={e => validateUsername(e.target.value)}
                                value={props.username}
                            />
                        </Form.Group>
                        <FormController
                            required={true}
                            validateEmail={validateEmail}
                            savePassword={savePassword}
                            password={props.password}
                            email={props.email}
                        />
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Confirm password</Form.Label>
                            <Form.Control
                                // className={props.error !== "" ? s.inValidValue : ""}
                                required={true}
                                type="password"
                                placeholder="Confirm Password"
                                onChange={(e) => validatePassword(e.target.value)}
                                value={props.validPassword}
                            />
                        </Form.Group>
                    </> :
                    <>
                        <FormController
                            validateEmail={validateEmail}
                            savePassword={savePassword}
                            password={props.password}
                            email={props.email}
                        />
                    </>
                }
                {props.registerCase !== undefined ?
                    props.registerCase.isPassValid ?
                        <>
                            <Button className="w-100 p-2 mt-3" variant="primary" type="submit" size="sm" active>
                                {Log}
                            </Button>
                        </>
                        :
                        <>
                            <Button className="w-100 p-2 mt-3" variant="primary" type="submit" size="sm" disabled>
                                {Log}
                            </Button>
                            <Form.Group className="mt-3 text-center" controlId="formBasicCheckbox">
                                <span>Already have and Account? </span>
                                <NavLink className="w-100 mt-3" to="/user/login">Login</NavLink>
                            </Form.Group>
                        </>
                    :
                    <>
                        <Button className="w-100 p-2 mt-3" variant="primary" type="submit" size="sm" active>
                            {Log}
                        </Button>
                        
                        <Form.Group className="mt-3 text-center" controlId="formBasicCheckbox">
                            <NavLink className="w-100 mt-3" to="/user/forgot_password">
                                Forgot Password?
                            </NavLink>
                        </Form.Group>
                    </>
                }
            </Form>
        </>
    )
}

export default Login;
