import type { ThunkDispatch } from "redux-thunk";
import { completeSighIn } from "../../api/firestore/API_SKD/getAPI";
import type {regUser, userInfo, LoginUser} from "../ReducerTypes";

export const initialState = {
    registerCase: {
        email: "",
        name: "",
        password: "",
        validPassword: "",
        isVIP: false,
        isPassValid: null
    } as regUser,
    loginCase: {
        email: "",
        password: "",
        isVIP: false
    } as LoginUser,
    error: "",
}

const valuesValidateReducer = (state: typeof initialState = initialState, action: any): typeof initialState => {
    let newState = { ...state }
    newState.registerCase = { ...newState.registerCase }
    newState.loginCase = { ...newState.loginCase }

    const _regCleaner = (login: boolean) => {
        const clearLogon = () => {
            newState.registerCase.name = "";
            newState.registerCase.validPassword = "";
            newState.registerCase.password = "";
            newState.registerCase.email = "";
            newState.registerCase.isVIP = false;
            newState.registerCase.isPassValid = false;
        }
        const clearLogin = () => {
            newState.loginCase.email = "";
            newState.loginCase.password = "";
            newState.loginCase.isVIP = false;
        }
        login ? clearLogon() : clearLogin();
        // window.location.replace("/section/challenges")
        // window.location.reload()
    }

    const _validateEmail = (email: string, isRegister: boolean) => {
        if (isRegister) {
            return newState.registerCase.email = email
        }

        return newState.loginCase.email = email;
    }

    const _validateUsername = (username: string) => {
        return newState.registerCase.name = username
    }

    const _savePassword = (password: string, isRegister: boolean) => {
        if (isRegister) {
            if (password.length < 8) newState.error = "Password should be at least 8 characters";
            return newState.registerCase.password = password
        }

        return newState.loginCase.password = password;
    }

    const _validatePassword = (newPassword: string) => {
        newState.registerCase.validPassword = newPassword;
        if (newPassword === newState.registerCase.password) {
            return newState.registerCase.isPassValid = true;
        };

        return newState.registerCase.isPassValid = false;
    }

    switch (action.type) {
        case "ADD_NEW_USERS_IN_LIST":
            return newState;
        case "SAVE_PASSWORD_IN_MEMORY":
            _savePassword(action.password.password, action.password.isRegister);
            return newState;
        case "VALIDATE_PASSWORD_IN_MEMORY":
            _validatePassword(action.validatedPassword);
            return newState;
        case "SAVE_EMAIL":
            _validateEmail(action.validEmail.email, action.validEmail.isRegister)
            return newState;
        case "SAVE_USERNAME":
            _validateUsername(action.username);
            return newState;
        case "KILL_VALUE":
            _regCleaner(action.login);
            return newState;
        default:
            return state
    }
}

/** @FORMS VALUES VALIDATION */
export const clearValues = (login: boolean) => ({ type: "KILL_VALUE", login })

const setUserEmailValidActionCreator = () => ({ type: "SET_USER_EMAIL_VALID" })
export const finishRegistration = () => (dispatch: any) => {
    completeSighIn(window.localStorage.getItem('emailForSignIn'), window.localStorage.getItem('tokenForSignIn'));
    dispatch(setUserEmailValidActionCreator())
}

const saveEmailActionCreator = (email: string, isRegister: boolean) => ({ type: "SAVE_EMAIL", validEmail: { email, isRegister } })
export const saveEmail = (email: string, isRegister: boolean) => (dispatch: ThunkDispatch<userInfo, void, any>) => {
    dispatch(saveEmailActionCreator(email, isRegister))
}

const saveUsernameActionCreator = (username: string) => ({ type: "SAVE_USERNAME", username })
export const saveUsername = (username: string) => (dispatch: ThunkDispatch<userInfo, void, any>) => {
    dispatch(saveUsernameActionCreator(username))
}

const savePasswordActionCreator = (password: string, isRegister: boolean) => ({ type: "SAVE_PASSWORD_IN_MEMORY", password: { password, isRegister } })
export const savePassword = (password: string, isRegister: boolean) => (dispatch: ThunkDispatch<userInfo, void, any>) => {
    dispatch(savePasswordActionCreator(password, isRegister))
}

const validatePasswordActionCreator = (validatedPassword: string) => ({ type: "VALIDATE_PASSWORD_IN_MEMORY", validatedPassword })
export const validatePassword = (validatedPassword: string) => (dispatch: ThunkDispatch<userInfo, void, any>) => {
    dispatch(validatePasswordActionCreator(validatedPassword))
}

const rememberUsernameActionCreator = (isSaved: boolean) => ({ type: "SAVE_USER_IN_MEMORY", isSaved })
export const rememberUsername = (isSaved: boolean) => (dispatch: ThunkDispatch<userInfo, void, any>) => {
    dispatch(rememberUsernameActionCreator(isSaved))
}

export default valuesValidateReducer