import { addDoc, collection } from "@firebase/firestore";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import type { ThunkDispatch } from "redux-thunk";
import db from "../../api/firestore/instance";
import type {Challenge, Admin, feedPanel} from "../ReducerTypes";
import {generateRandomString, toastOptions} from "../../hooks/Utility";
import {handleError} from "./user-reducer";
import { toast } from 'react-toastify';
import {challengesRequest} from "./feed-reducer";

export const initialState = {
    challenge: {
        id: "",
        name: "",
        ref: "",
        type: "Daily",
        scheduledDate: new Date(),
        solutionHint: "",
        code: "",
        description: "",
        VIPdesc: "",
        isSample: false,
        members: [] as Array<string>,
        outputs: [] as Array<string>,
    } as Challenge,
    feed: {
        title: "",
        article: "",
        id: "",
    } as feedPanel
} as Admin


/*setFirstOutput, updateOutput, setTaskName, setDescription,
    setVIPDescription, setCode, setType,updateOutputsArr, setSample, setTaskData, setScheduledDate,*/

const adminReducer = (state: typeof initialState = initialState, action: any): typeof initialState => {
    let newState = { ...state };
    newState.challenge = {...newState.challenge};
    newState.feed = { ...newState.feed };

    const _setTitle = (title: string) => newState.feed.title = title;
    const _setArticle = (article: string) => newState.feed.article = article;

    const _clearFeed = () => {
        newState.feed.id = "";
        newState.feed.title = "";
        newState.feed.article = "";
    }

    const _clearChallenge = () => {
        newState.challenge = {
            id: "",
            name: "",
            ref: "",
            type: "Daily",
            scheduledDate: new Date(),
            solutionHint: "",
            code: "",
            description: "",
            VIPdesc: "",
            isSample: false,
            members: [] as Array<string>,
            outputs: [] as Array<string>,
        }
        return newState.challenge;
    }

    const _setTaskData = (task: any) => {
        newState.challenge = task
    }

    switch (action.type) {
        case "SET_TASK":
            _setTaskData(action.task)
            return newState
        case "SET_TITLE":
            _setTitle(action.title)
            return newState;
        case "SET_ARTICLE":
            _setArticle(action.article)
            return newState;
        case "CLEAR_FEED":
            _clearFeed();
            return newState;
        case "CLEAR_CHALLENGE":
            _clearChallenge();
            return newState;
        default:
            return state
    }
}

const createNewTaskActionCreator = () => ({type: "CLEAR_CHALLENGE"})
export const addUpdateChallenge = (challenge: any) => async (dispatch: any) => {

    let requiredValues = ["VIPdesc","description","name","outputs","solutionHint","code"]

    challenge.outputs = challenge.outputs.flat()
    let incompleteValues = false
    requiredValues.forEach((value:string) => {
        if(typeof challenge[value] == "string" && challenge[value] == ""){
            incompleteValues = true
            console.log(value)
        }else if( Array.isArray(challenge[value]) && challenge[value].length === 0 ){
            incompleteValues = true
            console.log(value)
        }
    })

    if(incompleteValues){
        toast.warning("Please enter all the required value/s")
        return
    }
    const toastId = toast.loading("Updating! Please wait...")
    // toast.update(toastId, {render: "Failed! An error occured",type: "error",...toastOptions});

    const err = (err:any) => {
        toast.update(toastId, {render: "Failed! An error occured",type: "error",...toastOptions});
        console.log(err)
    }
    const success = (d:any) => {
        let render = challenge.ref == '' ? "Challenge Created": "Challenge Updated"
        toast.update(toastId, {render ,type: "success",...toastOptions});
    }

    try{
        if(challenge.ref){
            // Update challenge
            console.log('will update', challenge)
            updateDoc(doc(db, 'challenges', challenge.ref), challenge)
            .then(success, err)
        }else{
            // create challenge
            challenge.id = generateRandomString(16)
            //Delete because challenge is yet to be created.
            delete challenge.ref
            console.log('will create', challenge)
            addDoc(collection(db, "challenges"), challenge)
            .then(success, err)
        }
    }catch(e){
        console.log(e)
    }
}

const createNewPostActionCreator = () => ({type: "CLEAR_FEED"})
export const createNewPost = (post: feedPanel) => async (dispatch: any) => {
    const newPost = {...post};
    newPost.id = generateRandomString(12);
    try {
        await addDoc(collection(db, "feed"), newPost);
        dispatch(createNewPostActionCreator());
    }
    catch (err: any) {
        handleError(err.message);
    }
}

const setTitleActionCreator = (title: string) => ({ type: "SET_TITLE", title});
export const setTitle = (title: string) => (dispatch: any) => {
    dispatch(setTitleActionCreator(title));
}

const setArticleActionCreator = (article: string) => ({ type: "SET_ARTICLE", article});
export const setArticle = (article: string) => (dispatch: any) => {
    dispatch(setArticleActionCreator(article));
}

export const deleteChallenge = (challenge: any) => (dispatch: any) => {

    const id = toast.loading("Updating! Please wait...")
    try{        
        const refID = challenge.ref.substring(11)
        deleteDoc(doc(db, 'challenges', refID))
        .then(d => {
            toast.update(id, {...toastOptions,render: "Challenge deleted.",type: "success",});
            challengesRequest()(dispatch)
        })
        .catch(e => {
            toast.update(id, {...toastOptions,render: "Failed! An error occured",type: "error",});
        })
    }catch(e){
        toast.update(id, {...toastOptions,render: "Failed! An error occured",type: "error",});
    }
    dispatch(createNewTaskActionCreator());
}

export const createUsers = (users: any, uid: any) => (dispatch: ThunkDispatch<never, void, any>) => {

    const id = toast.loading("Creating Users! Please wait...")
    const toastOptions = {
        isLoading: false,
        autoClose:5000,
        hideProgressBar:false,
        draggable: true,
        closeOnClick: true,
    }

    fetch( `https://us-central1-algorithm-challenge.cloudfunctions.net/createUsers`, {
    // fetch( `http://127.0.0.1:5000/algorithm-challenge/us-central1/createUsers`, {
        method: 'POST',
        headers: {
            'Content-type' : 'application/json',
            'Authorization': 'Bearer '+ uid
        },
        body: JSON.stringify({users})
    })
    .then(async resp => {
        let json = resp.json()
        return {resp: json, status: resp.status}
    })
    .then(resp => {
        if(resp.status == 409){
            toast.update(id, {...toastOptions,render: "All Done!\n Some users already existed so were ignored",type: "warning"});
        }else{
            toast.update(id, {render: "Users Created",type: "success",...toastOptions})
        }
    })
    .catch(e => {
        toast.update(id, {...toastOptions,render: "Failed! An error occured",type: "error"});
        console.log(e.message)
    })
}

export default adminReducer