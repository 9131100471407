import React from "react";
import {connect} from "react-redux";
import type {Store} from "../../../redux/store"

//Components
import Blog from "./Blog";

//Styles
import s from './style.module.scss'
import bgImageSecondary from "../../../images/bgImageSecondary.png";

const mapStateToProps = (state: any) => {
    return {
        news: state.feed.news
    }
}

const BlogContext: React.FC<any> = (props) => {
    return (
        <div className={s.Cards}>
            {
                props.news.map((n: any) => {
                    return <Blog
                        article={n.article}
                        title={n.title}
                        key={n.id}
                    />
                })
            }
            <div className="bgImage">
                <img src={bgImageSecondary} alt="bgImage"/>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(BlogContext)
