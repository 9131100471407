import type {ThunkDispatch} from "redux-thunk";
import db from "../../api/firestore/instance";
import type {codeType, outputType, codeSection, taskSection } from "../ReducerTypes";
import { query, getDocs, collection, where, doc, limit, updateDoc, setDoc, connectFirestoreEmulator } from "firebase/firestore";
import {handleError} from "./user-reducer";
import {toastOptions} from "../../hooks/Utility";
import {toast} from 'react-toastify'

export const initialState: codeType = {
    codeSection: {
        code: "",
        input: "",
        output: "",
        error: "",
    } as codeSection,
    taskSection: {
        outputs: "",
        code: "",
        goal: ""
    } as taskSection,
    isCorrectly: false,
    // outputs: "",
}

/*const setMember = async (email: string, search: string, arrM: Array<any>, reference: string, taskName: string) => {
    const date = new Date()

    const docRef = doc(db, "challenges", reference.substring(11));
    try{
        updateDoc(docRef, {
            members: arrM
        })
        .then(res =>{
            console.log('-------------Running--------------')
        })
        .catch(e => {
            console.log('Error---------------', e)
        })
        await setDoc(doc(db, "completed_tasks", `${email}GJE4oI${taskName}`), {
            id: search,
            email,
            isCompleted: false,
            date: `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`,
            taskName
        })
        .then((res) => {
            console.log("success")
        })
    }catch (err: any) {handleError(err.message)}



}*/

const codeReducer = (state: typeof initialState = initialState, action: any): typeof initialState => {
    let newState = {...state};
    newState.codeSection = {...newState.codeSection};
    // newState.taskSection = {...newState.taskSection};

    const _inputCode = (code: string, isCodeTextarea: boolean) => {
        isCodeTextarea ? newState.codeSection.code = code : newState.codeSection.input = code;
    }

    const _setOutput = (data: outputType) => {
        if (data.stderr !== "") return newState.codeSection.output = data.stderr;
        if (data.stdout !== "") return newState.codeSection.output = data.stdout.substring(0, data.stdout.indexOf('\n'));
        if (data.stdsuc !== "") return newState.codeSection.output = data.stdsuc;
    }

    const _cleaner = () => {
        newState.codeSection.output = "";
        newState.codeSection.code = "";
    }

    const _setOutputParams = (challenge: taskSection) => {
        // return null

        newState.taskSection.code = challenge.code
        newState.taskSection.outputs = challenge.outputs;
        newState.taskSection.goal = challenge.goal;
        return newState.taskSection
    }

    const _compare2ansewrs = (output: string, example: string | Array<string>) => {
        let isCorrect = false
        if(Array.isArray(example)) {
            for(let i = 0; i < example.length; i++) {
                if(`${example[i]}\n` === output) isCorrect = true;
                // continue;
            }
        }

        else {
            if(`${example}\n` === output) isCorrect = true;
            else {isCorrect = false}
        }
        
        isCorrect ?
            toast.update(window.sessionStorage.toastID,{...toastOptions, render: "Welldone, Code is correct", type: "success"})
        :
            toast.update(window.sessionStorage.toastID,{...toastOptions, render: "Output doesn't match." ,type: "error"})
        return newState.isCorrectly = isCorrect
    }

    const _taskCompleted = () => {
        newState.isCorrectly = false;
        // window.location.replace("/section/challenges")
    }

    switch(action.type) {
        case "INPUT":
            _inputCode(action.code, action.isCodeTextarea)
            return newState;
        case "SET_OUTPUT":
            _setOutput(action.output);
            return newState;
        case "CLEAR_OUTPUT":
            _cleaner();
            return newState;
        case "SET_TASK_VALUE":
            _setOutputParams(action.value);
            return newState;
        case "COMPARE_OUTPUT_WITH_EXAMPLE":
            _compare2ansewrs(action.ot, action.ex)
            return newState;
        case "COMPLETE_TASK":
            _taskCompleted()
            return newState;
        default:
            return state
    }
}

const inputActionCreator = (code: string, isCodeTextarea: boolean) => ({type: "INPUT", code, isCodeTextarea })

export const inputCode = (event: any) => (dispatch: ThunkDispatch<codeType, void, any>) => {
    dispatch(inputActionCreator(event, true))
}

export const inputValue = (event: any, code: boolean) => (dispatch: ThunkDispatch<codeType, void, any>) => {
    dispatch(inputActionCreator(event, false))
}

const outputActionCreator = (output: outputType) => ({type: "SET_OUTPUT", output})
export const getOutput = (code: codeSection, outputs: string | Array<string>) => (dispatch: ThunkDispatch<codeSection, void, any>) => {
    
    const toastID = toast.loading("Compiling Code...")
    window.sessionStorage.toastID = toastID

    fetch('https://us-central1-algorithm-challenge.cloudfunctions.net/runCode', {
        withCredentials: true,
        headers: {
            'Authorization': 'Token 2a441d77-bd28-4551-a7c0-0120a076cab5',
            'Content-type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
            "stdin": code.input,
            "files": [
                {
                    "name": "main.py",
                    "content": code.code
                }
            ]
        })
    } as any)
    .then((data) => {
        return data.json()
    })
    .then((data: outputType) => {
        // console.log(data, outputs)
        dispatch(outputActionCreator(data))
        dispatch(compareAnswersActionCreator(data.stdout, outputs))
    })
    .catch((err: any) => {
        toast.update(toastID, {...toastOptions,render: "Failed! Some error occured",type: "error"})
        handleError(err.message)
    })
}

const getInfoActionCreator = (value: taskSection) => ({type: "SET_TASK_VALUE", value})

// Исправлено, можно удалять
// HKS حذف نہ کریں۔
// HKS: Не удалять

export const getInfo = (taskID: string) => async (dispatch: ThunkDispatch<string, void, any>) => {
    const q = query(collection(db, "challenges"), where("id", "==", `${taskID}`));
    
    const querySnapshot = await getDocs(q);

    try {
        querySnapshot.forEach((doc: any) => {
            dispatch(getInfoActionCreator(doc.data()));
            return doc.data()
        });
    } catch (err:any) {handleError(err.message)}
}


export const getTask = async (taskID: any) => {

    let q;
    if(taskID){
        q = query(collection(db, "challenges"), where("id", "==", taskID), limit(1));
    }else{
        let todayStart = new Date()
        todayStart.setHours(0,0,0,0)
        let todayEnd = new Date()
        todayEnd.setHours(23,59,59,0) 
        q = query(collection(db, "challenges"), where("scheduledDate", ">=", todayStart), where("scheduledDate", "<=", todayEnd), limit(1));
    }
    let res = await getDocs(q);
    if( res.docs.length > 0 ){
        return res
    }else{
        q = query(collection(db, "challenges"), where("defaultTask", "==", true), limit(1));
        return getDocs(q)
    }
}

export const uploadCode = (exCode: string) =>  async (dispatch: ThunkDispatch<string, void, any>) => {
//     const docRef = doc(db, "challenges", "ZNIGhgsKNl5lpVoTcbRx");
// try{
// await updateDoc(docRef, {
//     code: exCode
// })
// }catch (err) {console.log(err)}
}

export const startTask = (user: any, challenge: any, updateTask:any, code:any = {}) => (dispatch: ThunkDispatch<string, void, any>) => {

    try{
        if(!Array.isArray(challenge.members) || !challenge.members.includes(user.email)){

            // If the email doesnot exists already,
            // we will get the latest challenge data an update the updated members list
            getTask(challenge.id)
            .then(res =>{
                if(res.docs.length > 0 ){
                    let task:any = res.docs[0].data()
                    let members = task.members || []
                    if( !members.includes(user.email) ){
                        members.push(user.email)
                        updateDoc(doc(db, "challenges", challenge.ref), {members})
                        .then(res =>{
                            challenge.members = members
                            updateTask(challenge, challenge.ref)
                        })
                        .catch(e => {
                            console.log('Error---------------', e)
                        })
                    }
                }
            }).catch(e =>console.log(e))

        }
        // return

        setDoc(doc(db, "completed_tasks", `uid:${user.uid}&cid:${challenge.ref}`), {
            cid:        challenge.id,
            userID:     user.uid,
            userCode:   code.code,
            email:      user.email,
            taskName:   challenge.name,
            isCompleted:false,
            date:       new Date(),
        })
        .then((res) => {
            // console.log("success")
        }).catch(e =>console.log(e))


    }catch (err: any) {handleError(err.message)}
    /*if(email !== "") {
        tasks.map((element: any) => {
            if(element.members !== undefined) {
                if(!element.members.includes(email) && element.id === search) {
                    element.members.push(email)
                    setMember(email, search, element.members, element.ref, element.name)
                }
            }
            if(element.members === undefined && element.id === search) {
                let arr = [email];
                
                setMember(email, search, arr, element.ref, element.name)
            }
        })
    }*/
}


const completeTaskActionCreator = () => ({type: "COMPLETE_TASK"});
export const completeTask = (user: any, challenge:any) =>  async (dispatch: ThunkDispatch<string, void, any>) => {
    try{
        await updateDoc(doc(db, "completed_tasks", `uid:${user.uid}&cid:${challenge.ref}`), {
            isCompleted: true
        })
        dispatch(completeTaskActionCreator());
    }
    catch (err: any) {
        handleError(err.message)
    }
}
export const leavePage = () => (dispatch: ThunkDispatch<never, void, any>) => {
    dispatch(completeTaskActionCreator());
}

const outputCleanActionCreator = (enters: string) => ({type: "CLEAR_OUTPUT", enters})
export const clearOutput = (enters: string) => (dispatch: ThunkDispatch<codeType, void, any>) => {
    dispatch(outputCleanActionCreator(enters))
}

const compareAnswersActionCreator = (ot: string, ex: string | Array<string>) => ({type: "COMPARE_OUTPUT_WITH_EXAMPLE", ot, ex});

export default codeReducer