import { doc, updateDoc } from "firebase/firestore";
import type { ThunkDispatch } from "redux-thunk";
import db from "../../api/firestore/instance";
import type {paymentType, userAuthType} from "../ReducerTypes";
import {checkUserAuthActionCreator} from "./user-reducer";
import {handleError} from "./user-reducer";

export const initialState: paymentType = {
}

const paymentReducer = (state: typeof initialState = initialState, action: any): typeof initialState => {
    let newState = { ...state }
    switch (action.type) {
        default:
            return state
    }
}

const clearUSerProfileActionCreator = () => ({type: "CLEAR_USER_TASKS"})
export const acceptPayment = (CardElement: any, stripe: any, elements: any, userID: string, userStatus: userAuthType) => async (dispatch: ThunkDispatch<never, void, any>) => {

    let updatedUserStatus = {...userStatus}

    const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
    });

    if (!error) {
        console.log("Stripe 23 | token generated!", paymentMethod);

        const { id } = paymentMethod;
        console.log(id)

        fetch('https://us-central1-algorithm-challenge.cloudfunctions.net/activePayment', {
            withCredentials: true,
            headers: {
                'Authorization': 'Token 2a441d77-bd28-4551-a7c0-0120a076cab5',
                'Content-type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({
                amount: 30000,
                id,
            })
        } as any)
            .then((data) => {
                return data.json()
            })
            .then(async (response: any) => {
                if (response.success) {
                    const docRef = doc(db, "users", userID);
                    
                    await updateDoc(docRef, {
                        isVIP: true,
                    })
                    .then(() => {
                        updatedUserStatus.isVIP = true;
                        dispatch(checkUserAuthActionCreator(updatedUserStatus));
                        window.location.replace(`/user/profile/id_${userStatus.userID}`)
                    })
                }
            })
            .catch((err: any) => {
                handleError(err.message);
            })
    }
    else {
        console.log(error.message);
    }
}

export default paymentReducer