import React from "react";
import {logout} from "../../redux/reducers/user-reducer"
import {connect} from "react-redux"
import {Redirect} from "react-router-dom"

const mapStateToProps = (state: any) => {
    return {}
}

const Logout: React.FC<any> = (props) => {
    React.useEffect(() => {
        props.logout()
    }, [])
    return (
    <div>
        <Redirect to="/section/challenges"/>
    </div>
    )
}

export default connect(mapStateToProps, {logout})(Logout);