import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

//Components
import { Button, Form, Modal } from "react-bootstrap";

//Styles
import s from "./style.module.css";

const Payment = (props: any) => {
    const stripe: any = useStripe();
    const elements: any = useElements();

    const cancelPayment = (event: any) => {
        event.preventDefault();

        props.path();
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        props.acceptPayment(CardElement, stripe, elements, props.userRefId, props.isAuth);
    };

    return (
        <>
            <Form onSubmit={handleSubmit} className={`${s.container4} setToCenter`}>
                <Modal.Dialog>
                    <Modal.Header className={s.newFooter}>
                        <Modal.Title className={s.toRight}>Upgrade your plan</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <h4 className={s.toRight}>Only for 300$</h4>
                    </Modal.Body>
                    <Modal.Body>
                        <CardElement />
                    </Modal.Body>

                    <Modal.Footer className={s.newFooter}>
                        <Button variant="primary" type="submit">Accept payment</Button>
                        <Button variant="secondary" onClick={cancelPayment}>Close</Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </Form>
        </>
    )
}

export default Payment;
