import React from 'react';
import {connect} from "react-redux";
import {Route, Switch} from 'react-router-dom';
import {Alert} from "react-bootstrap";

import Register from "./login/RegisterContext"
import Login from './login/LoginContext';
import ForgotPassword from './login/ForgotPassword';
import Logout from "./login/Logout";
import Landing from "./landing/LandingContext";
import Header from "./bar/Header";
import FinishSignUp from "./login/FinishSignUp"
import Feed from "./landing/feed/FeedContext";
import Blog from './landing/blog/BlogContext';
import Profile from "./profile/ProfileContext";
import Payment from "./payment/PaymentContext";
import NotFound from "./404/404";

import CreateEditTaskContext from "../admin-panel/CreateEditTaskContext";
import FeedAdminPanel from "../admin-panel/FeedAdminPanel"
import AdminPanelContext from "../admin-panel/AdminPanelContext";
import VIPUsersLinksContext from "../admin-panel/VIPUsersLinksContext";
import EnrollUsersContext from "../admin-panel/EnrollUsersContext";

import {challengesRequest, clearTable, newsRequest} from "../redux/reducers/feed-reducer";
import {checkUserAuth} from "../redux/reducers/user-reducer"
import WithUserAuth  from '../hoc/WithUserAuth';
import "../../node_modules/highlight.js/styles/hybrid.css";

import FadingDots from "react-cssfx-loading/lib/FadingDots";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Main.scss";
// import type {Store} from "../redux/store"

const mapStateToProps = (state) => {
    return {
        isUserAuth: state.users.isUserAuth,
        isAuth: state.users.isAuth,
        ERROR: state.users.error,
    }
}

const Main = (props) => {

    React.useEffect(() => {
        props.challengesRequest()
        props.checkUserAuth()
        props.newsRequest()

        return props.clearTable()
    }, [])

    return (
        <>
            {!props.isUserAuth.checked ?
                <div className="pagePreloader">
                    <FadingDots width="150px" height="150px" color="#62d3f1" />
                </div>
            :
                <div>
                    <Header isUserAuth={props.isUserAuth} />
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <>
                        {props.ERROR !== "" ?
                        <>
                            <Alert variant={'danger'} className="Alert">
                                ERROR! {props.ERROR.replaceAll(/(Firebase\:|auth\/)/ig, '' )}
                            </Alert>
                        </>: ''}
                    </>
                     <Switch>
                        <Route exact path="/" render={() => <Landing />} />

                        <Route exact path="/admin/task/" render={() => <CreateEditTaskContext />} />
                        <Route exact path="/admin/panel/" render={() => <AdminPanelContext />} />
                        <Route exact path="/admin/vip_links/" render={() => <VIPUsersLinksContext />} />
                        <Route exact path="/admin/enrol_user/" render={() => <EnrollUsersContext />} />
                        <Route exact path="/admin/panel/feed" render={() => <FeedAdminPanel /> } />

                        <Route exact path="/section/payment" render={() => <Payment />}/>
                        <Route exact path="/section/code" render={() => <Landing />} />
                        <Route path="/user/profile" render={() => <Profile />} />
                        
                        <Route exact path="/section/challenges" render={() => <Feed />} />
                        <Route exact path="/section/feed" render={() => <Blog />} />

                        <Route exact path="/user/register" render={() => <Register />} />
                        <Route exact path="/user/login" render={() => <Login />} />
                        <Route exact path="/user/forgot_password" render={() => <ForgotPassword />} />
                        <Route exact path="/user/logout" render={() => <Logout />} />
                        <Route exact path="/user/finishSignUp" render={() => <FinishSignUp />} />
                        
                        <Route path="*" render={() => <NotFound />} />
                     </Switch> 
                </div>
            }
        </>
    )
}

export default connect(mapStateToProps, {checkUserAuth, challengesRequest, clearTable, newsRequest })(Main)
