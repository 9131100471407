import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Payment from "./Payment";
import { useHistory, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import type { Store } from "../../redux/store";
import  { acceptPayment } from "../../redux/reducers/payment-reducer";

const PUBLIC_KEY = "pk_test_51JlWD2AdFfRwpgzTQWzkOaXT0kl8vy6YbC8XXOH3NWR0nHoUha8DS3BeMjtr4zfsoQUcs2ZL5j1RhBLBqiWl6Cii00Rvkoz6y1";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

function mapStateToProps(state: Store) {
  return {
    isAuth: state.users.isUserAuth,
    userRefId: state.users.userRefId
  }
}

const PaymentContext = (props: any) => {
  const history = useHistory();
  const path = () => history.push(`/user/profile/id_${props.isAuth.userID}`);
  
  console.log(props.isAuth.isAuth)
  return (
    <>
      { !props.isAuth.isAuth ?
          <Redirect to="/user/login" />
      :
        <Elements stripe={stripeTestPromise}>
          <Payment
            path={path}
            acceptPayment={props.acceptPayment}
            userRefId={props.userRefId}
            isAuth={props.isAuth}
          />
        </Elements>
      }
    </>
  );
}

export default connect(mapStateToProps, {acceptPayment})(PaymentContext)