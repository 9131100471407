import { collection, query } from "@firebase/firestore";
import { getDocs, where } from "firebase/firestore";
import type { ThunkDispatch } from "redux-thunk";
import db from "../../api/firestore/instance";
import type {userTasks, userTasksType} from "../ReducerTypes";
import {handleError} from "./user-reducer";


export const initialState: userTasksType = {
    userTasks: [] as Array<userTasks>
}

const profileReducer = (state: typeof initialState = initialState, action: any): typeof initialState => {
    let newState = { ...state }
    newState.userTasks = [...newState.userTasks];

    const _setUserTasks = (tasks: userTasks) => {
        return newState.userTasks.push(tasks);
    }

    const _clearUserTasks = () => {
        return newState.userTasks = [];
    }

    switch (action.type) {
        case "SET_USER_TASKS":
            _setUserTasks(action.tasks)
            return newState;
        case "CLEAR_USER_TASKS":
            _clearUserTasks()
            return newState;
        default:
            return state
    }
}

const getUserTasksActionCreator = (tasks: userTasks) => ({type: "SET_USER_TASKS", tasks})
export const getUserTasks = (email: string) => async (dispatch: ThunkDispatch<Array<userTasks>, void, any>) => {
    const q = query(collection(db, "completed_tasks"), where("email", "==", email));
    const querySnapshot = await getDocs(q);

    try {
        querySnapshot.forEach((doc: any) => {
            let data:any = doc.data()
            
            dispatch(getUserTasksActionCreator({
                email: data.email,
                date: data.date,
                taskName: data.taskName,
                isCompleted: data.isCompleted,
                id: data.id || data.cid,
                userCode: data.userCode,
            }))
        });
    } catch (err: any) {
        handleError(err.message)
    }
}

const clearUSerProfileActionCreator = () => ({type: "CLEAR_USER_TASKS"})
export const clearUserProfile = () => (dispatch: ThunkDispatch<never, void, any>) => {
    dispatch(clearUSerProfileActionCreator())
}

export default profileReducer