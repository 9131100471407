import React from "react";
import {NavLink} from "react-router-dom";
import {Navbar, Nav} from "react-bootstrap";

//Icons
import {FaUserAlt, FaSignInAlt, FaSignOutAlt, FaRegUserCircle } from "react-icons/fa";

//Images
import Logo from '../../images/Logo.png';

//Styles
import s from './style.module.scss'

const Header: React.FC<any> = (props) => {
    return (
        <>
            <Navbar collapseOnSelect className={s.Header} expand="lg">
                <Navbar.Brand className={s.HeaderMenuLogo}>
                    <NavLink to="/"><img src={Logo} alt="logo"/></NavLink>
                </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
                    <Nav className={s.HeaderMenu}>

                        {props.isUserAuth.isAdmin ?
                        <Navbar.Brand>
                            <NavLink to="/section/challenges">
                            Algorithms Challenge
                            </NavLink>
                        </Navbar.Brand>
                        :''}
                        <Navbar.Brand>
                            <NavLink to="/section/feed">Blog</NavLink>
                        </Navbar.Brand>
                        {props.isUserAuth.isAdmin?
                            <Navbar.Brand>
                                <NavLink to="/admin/panel">Admin</NavLink>
                            </Navbar.Brand>
                        :''}
                    </Nav>
                    <Nav className={s.HeaderUser}>
                        {props.isUserAuth.isAuth
                            ? <>
                                <NavLink
                                    to={`/user/profile/`}>
                                    <FaRegUserCircle/> {props.isUserAuth.email}
                                </NavLink>
                                <NavLink to="/user/logout"><FaSignOutAlt/> Logout</NavLink>
                            </>
                            : <>
                                <NavLink to={`/user/login`}><FaSignInAlt/> Login</NavLink>
                                <NavLink to="/user/register"><FaUserAlt/> Register</NavLink>
                            </>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

export default Header
