import { doc, getDocs, deleteDoc, writeBatch, collection} from "firebase/firestore";
import type { ThunkDispatch } from "redux-thunk";
import { toast } from "react-toastify";

import db from "../../api/firestore/instance";
import type {vipLinksType} from "../ReducerTypes";
import {toastOptions, generateRandomString} from "../../hooks/Utility";
import {handleError} from "./user-reducer";


export const initialState: vipLinksType = {
    loading: true,
    couponCodes: [],
}

let toastID:string;

const vipLinksReducer = (state: typeof initialState = initialState, action: any): typeof initialState => {
    let newState = { ...state }
    newState.couponCodes = [...newState.couponCodes ];

    const _updateLoader = () => {
        return newState.loading  = action.status;
    }

    const _loadedLinks = () => {

        // action.links.forEach(coupon => {
        //     let existing = 
        // })
        return newState.couponCodes  = [...newState.couponCodes, ...action.links];
    }

    const _removeLink = (coupon:any) => {
        return newState.couponCodes  = newState.couponCodes.filter( (couponCode:any) => {
            return couponCode.id != coupon.id
        })
    }

    switch (action.type) {
        case "LOADER_STATUS":
            _updateLoader()
            return newState;
        case "LOADED_LINKS":
            _loadedLinks()
            return newState;
        case "REMOVE_LINK":
            _removeLink(action.coupon)
            return newState;
        case "CLEAR_LINKS":
            newState.couponCodes = []
            return newState;
        default:
            return state
    }
}

const loaderActionCreator = (status:boolean) => ({type: "LOADER_STATUS", status})
const loadVIPLinksActionCreator = (links:any) => ({type: "LOADED_LINKS", links})
const removeLinkActionCreator = (coupon:any) => ({type: "REMOVE_LINK", coupon})
const clearLinksActionCreator = () => ({type: "CLEAR_LINKS"})

export const loadLinks = () => (dispatch: ThunkDispatch<never, void, any>) => {

    dispatch(loaderActionCreator(true))
    getDocs(collection(db, "vip_users_links"))
    .then(results => {
        let docs:any = []
        results.forEach(doc => {
            docs.push({...doc.data(), id: doc.id})
        })
        dispatch(loadVIPLinksActionCreator(docs))
        dispatch(loaderActionCreator(false))
    })
    .catch(e => {
        dispatch(loaderActionCreator(false))
    })
}

export const createLinks = (count:Number) => (dispatch: ThunkDispatch<never, void, any>) => {

    dispatch(loaderActionCreator(true))
    const toastID = toast.loading("Creating links! Please wait...")
    
    let batch = writeBatch(db)

    // Collect the ids as well
    let newLinks:any = []    
    for(let i = 0; i < count; i++ ){
        newLinks[i] = {
            used: false,
            couponCode: generateRandomString(21)
        }
        batch.set(doc(db, 'vip_users_links', generateRandomString(20)), newLinks[i])
    }

    batch.commit()
    .then(res => {
        toast.update(toastID,{...toastOptions,autoClose:3000, render: 'New Links created', type: 'success'})
        dispatch(loadVIPLinksActionCreator(newLinks))
        dispatch(loaderActionCreator(false))
    })
    .catch(e => {
        toast.update(toastID,{...toastOptions, render: 'There were some issue creating the links', type: 'error'})
        dispatch(loaderActionCreator(false))
        console.log(e)
    })
}

export const deleteLink = (coupon:any) => (dispatch: ThunkDispatch<never, void, any>) => {

    const toastID = toast.loading("Deleting link! Please wait...")
    deleteDoc(doc(db, 'vip_users_links', coupon.id))
    .then(res => {
        dispatch(removeLinkActionCreator(coupon))
        toast.update(toastID,{...toastOptions, autoClose:3000, render: 'Link deleted successfully', type: 'success'})
    })
    .catch(e => {
        toast.update(toastID,{...toastOptions, render: 'There were some issue creating the links', type: 'error'})
        console.log(e)
    })
}

export const emptyLinksArray = () => (dispatch: ThunkDispatch<never, void, any>) => {
    dispatch(clearLinksActionCreator())
}
export const downloadLinks = (coupons:any) => {
    let str = '';
    coupons.forEach((coupon:any) => {
        str += `${window.location.origin}/user/register?_track_id=${coupon.couponCode}\n`
    })

    let downloadLink = document.createElement("a");
    let blob = new Blob(["\ufeff", str]);
    let url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "links.csv";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export default vipLinksReducer