import React from "react";
import {connect} from "react-redux";
import {NavLink, Redirect} from "react-router-dom";
import {Tabs, Tab} from "react-bootstrap";
import {toast} from "react-toastify";
import {MdCopyAll, MdDeleteOutline} from "react-icons/md"
import {FaFileCsv} from "react-icons/fa"
import type {Store} from "../redux/store"
import {toastOptions} from "../hooks/Utility";

import {loadLinks, createLinks, deleteLink, emptyLinksArray, downloadLinks} from "../redux/reducers/vip-links-reducer";

const mapStateToProps = (state: Store) => {
	return {
		user: state.users,
		vipLinks: state.vipLinks,
	}
}

const VIPUsersLinksContext = (props: any) => {

	React.useEffect(()=>{
		// empty the array that contains previously loaded links/coupons
		props.emptyLinksArray()
		// Load again fresh urls
		props.loadLinks()
	},[])

	if(!props.user.isUserAuth.isAdmin || !props.user.isUserAuth.userID){
		return (
			<Redirect to="/section/challenges" />
		)
	}

	const handleSubmit = (e: any) => {
		e.preventDefault()
		let count = e.target["amount"] && parseInt(e.target["amount"].value)
		if(!count || count < 1){
			toast.warning("Please enter number of links you want to generate")
			return
		}

		props.createLinks(count)
	}

	const copyLink = (coupon:any) => {
		 navigator.clipboard.writeText(`${window.location.origin}/user/register?_track_id=${coupon.couponCode}`);
		 toast.success("Copied!")
	}


	const GenerateNewLinksForm = () => {
		return (
			<>
				<form onSubmit={handleSubmit}>
					
					<label >How many links you want to generate?</label>
					<div className="input-group mb-3">
						<input
							className="form-control"
							placeholder="Number of links to generate"
							name="amount"
							type="number"
							id="links-count"
						/>
						<div className="input-group-append">
							<input
								type="submit"
								value="Create"
								className="btn btn-primary"
								style={{minWidth: '150px', height: '50px'}}
								disabled={props.vipLinks.loading}
							/>
						</div>
					</div>
				</form>
			</>
		)
	}


	const LinksRows = (localProps:any) => {
		if(localProps.coupons.length == 0){
			return (
				<tr>
					<td colSpan={3}>
					{(!props.vipLinks.loading) ?
						<div className="d-flex justify-content-center mt-3">No links here.</div>
						:
						<div className="d-flex justify-content-center mt-3">Loading...</div>

					}
					</td>
				</tr>
				)
		}
		return localProps.coupons.map((coupon:any, index:number) => {
			return (
                <tr className="align-middle" key={index}>
                    <th>
                    	{index+1}
                    </th>
                    <td>
                        {`${window.location.origin.replace(/http:\/\/||https:\/\//, '').substr(0, 15)}...k_id=${coupon.couponCode}`}
                    </td>
                    {localProps.type == 'used' && <td>{coupon.userEmail}</td>}
                    <td className="row mx-0 text-center">
                        <div className="col-sm-6 px-1">
                            <MdCopyAll
                            	size="30px"
                            	color="blue"
                            	style={{cursor: 'pointer'}}
                            	onClick={e => copyLink(coupon)}
                            />
                        </div>
                        <div className="col-sm-6 px-1">
                            <MdDeleteOutline
                            	size="30px"
                            	color="red"
                            	style={{cursor: 'pointer'}}
                            	onClick={e => props.deleteLink(coupon)}
                            />
                        </div>
                    </td>
                </tr>
			)
		})
	}

	const LinksTable = (localProps:any) => {

		let coupons:any = localProps.type == 'used' ? props.vipLinks.couponCodes.filter((coupon:any) => coupon.used ) : props.vipLinks.couponCodes.filter((coupon:any) => !coupon.used)
		return(
	        <div className="px-3 mx-auto pt-4" style={{maxWidth: '1200px'}}>
	            <div className="w-100 p-3 d-flex justify-content-end">
	            	{coupons.length > 0 && <button className="btn btn-primary" onClick={e => downloadLinks(coupons)}><FaFileCsv size="20px" /> Download Links</button>}
	            </div>
	            <div className="w-100 px-3">
	                <table className="table table-hover table-striped" >
	                    <thead style={{background: '#777', color: '#fff'}}>
	                        <tr>
	                        	<th scope="col">#</th>
	                        	<th scope="col">URL</th>
	                        	{localProps.type == 'used' && <th scope="col">User Email</th>}
	                        	<th scope="col" className="text-center">Options</th>
	                        </tr>
	                    </thead>
	                    <tbody>
	                    	<LinksRows
	                    		coupons={coupons}
	                    		type={localProps.type}
	                    	/>
	                    </tbody>
	                </table>
	            </div>
	        </div>
	    )
	}

	return (
		<>
			<div className="container">
				<Tabs defaultActiveKey="available" id="vip-links-tabs" className="mb-3 flex-nowrap">
					<Tab eventKey="available" title="Available/Unused links">
						<LinksTable type="available" />
					</Tab>
					<Tab eventKey="used" title="Used Links">
						<LinksTable type="used" />
					</Tab>
					<Tab eventKey="generate" title="Generate New Links">
						<GenerateNewLinksForm/>
					</Tab>
				</Tabs>
			</div>
		</>
	)
}

export default connect(mapStateToProps, {loadLinks, createLinks, deleteLink, emptyLinksArray})(VIPUsersLinksContext)