import React from "react";
import {challengesRequest, clearTable} from "../../../redux/reducers/feed-reducer";
import {connect} from "react-redux";
import type {Store} from "../../../redux/store"
import {Redirect} from "react-router-dom";

//Components
import Feed from "./Feed"
import {Col, Container, Row} from "react-bootstrap";

//Styles
import s from './style.module.scss'
import bgImageSecondary from "../../../images/bgImageSecondary.png";

const mapStateToProps = (state: Store) => {
    return {
        tasks: state.feed.tasks,
        isUserAuth: state.users.isUserAuth,
        user: state.users
    }
}

const FeedContext = (props: any) => {

    if( !props.user.isUserAuth.isAdmin || !props.user.isUserAuth.userID ){
        return (
            <Redirect to="/" />
        )
    }

    return (
        <div className={s.FeedSection} style={{backgroundImage: `url(${bgImageSecondary})`}}>
            <Container fluid className="w-100 p-0">
                <div className={s.Cards}>
                    {props.tasks.map((t: any, index: number) => {
                        return <Feed
                            task={t}
                            runable={props.isUserAuth.isAuth || t.isSample}
                            key={index}
                        />
                    })}
                </div>
                {/*<div className="bgImage">
                    <img src={bgImageSecondary} alt="bgImage"/>
                </div>*/}
            </Container>
        </div>
    )
}

export default connect(mapStateToProps, {challengesRequest, clearTable})(FeedContext)
